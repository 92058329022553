@use "../../../../scss/utils/Breakpoints.module.scss" as breakpoints;

// Variables

:root {
  --item-mb: 6px;
  --item-size: 60px;
  --item-radius: var(--radius-small);
}

// Recipients

.recipients {
  display: grid;
}

.recipientType {
  color: var(--color-gray-600);
}

.viewers {
  margin-bottom: var(--item-mb);
}

.recipient {
  display: flex;
  background-color: var(--color-white);
  height: auto;
  border-radius: var(--item-radius);
  margin-bottom: var(--item-mb);
  font-size: var(--font-size-sm);

  @include breakpoints.breakpoint-up(md) {
    height: var(--item-size);
  }
}

.numberWrapper {
  color: var(--color-gray-600);
  display: flex;
  align-items: flex-start;
  padding: var(--spacing-5) var(--spacing-4);
  width: var(--item-size);
  min-width: var(--item-size);
  border-right: var(--border-width) var(--border-color) solid;

  @include breakpoints.breakpoint-up(md) {
    align-items: center;
  }

  svg {
    color: var(--color-gray-600);
  }

  .indexNumber {
    text-align: left;
  }

  .indexIcon {
    text-align: left;
  }
}

.recipientGroup {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .recipient;

  margin-bottom: 0;
  background-color: transparent;
  border-right: var(--border-width) var(--border-color) solid !important;

  .numberWrapper {
    border-right: var(--border-width) var(--border-color) solid !important;
  }
}

.contentWrapper {
  display: grid;
  align-items: center;
  padding: var(--spacing-5) var(--spacing-4);
  width: 100%;

  & div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @include breakpoints.breakpoint-up(md) {
    grid-template-columns: 6rem repeat(4, 1fr);
  }

  @include breakpoints.breakpoint-up(xl) {
    padding: var(--spacing-5) var(--spacing-6);
  }
}

.workflowGroup {
  background-color: var(--color-white);
  height: auto;
  min-height: var(--item-size);
  border-radius: var(--item-radius);
  margin-bottom: var(--item-mb);
  border: var(--border-width) solid transparent;
}

.workflowRecipient {
  border: var(--border-width) solid transparent;

  &.group {
    background-color: transparent;
    margin-bottom: 0;
    cursor: default;
    border-top: 1px solid var(--border-color);
    border-radius: 0;

    .numberWrapper {
      border-right: 0;
    }
  }
}

// Override Accordion component styles

.accordion {
  border-bottom: unset !important;
  background-clip: border-box !important;
}

.accordionHeader {
  padding: 0 !important;
  background: transparent !important;
}

.accordionContents {
  padding: unset !important;
}

.accordionChevron {
  border-left: var(--border-width) solid var(--border-color) !important;
}

// Apply interactive styles to sortable items
.active {
  .accordionContents {
    display: none !important;
  }
}

.collapsed {
  .accordionContents {
    display: none !important;
  }

  .accordionChevron {
    color: var(--color-gray-100) !important;
  }
}

.sortable {
  touch-action: manipulation;
  cursor: grab;
  transition: scale 0.12s ease-out;

  .accordionHeader {
    touch-action: manipulation !important;
    cursor: grab !important;
  }

  &:hover:not(.group.workflowRecipient) {
    box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
    border: var(--border-width) solid var(--color-tab-hover);
  }

  &.active {
    &:not(.group.active) {
      box-shadow: 0 2px 12px rgb(0 0 0 / 20%);
      border: var(--border-width) solid var(--color-tab-hover);
      z-index: 1;
      scale: 1.03;
    }

    .accordionContents {
      display: none !important;
    }
  }
}

.editButtonWrapper {
  @include breakpoints.breakpoint-up(md) {
    display: flex;
    justify-content: flex-end;
  }
}

.emptyDiv {
  width: var(--item-size);
  min-width: calc(var(--item-size) - (calc(var(--border-width)) * 2));
  max-width: calc(var(--item-size) - (calc(var(--border-width)) * 2));
  border-left: var(--border-width) solid transparent;
}

.complete {
  color: var(--color-success);
}

.workflowActionsFooter {
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  width: 100%;
}

.deleteRecipientButton {
  width: 48px;
  min-width: 48px;
  display: flex;
  padding-right: var(--spacing-5);
  background-color: transparent;
  align-self: center;

  &:hover {
    svg {
      color: var(--color-primary);
    }
  }

  @include breakpoints.breakpoint-up(md) {
    align-items: center;
  }
}

.workflowGroupFooter {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .workflowActionsFooter;

  padding: var(--spacing-5) var(--spacing-4) var(--spacing-5) calc(var(--item-size) + var(--spacing-6));
  border-top: var(--border-width) solid var(--border-color);
  border-bottom-right-radius: var(--item-radius);
  border-bottom-left-radius: var(--item-radius);

  button {
    /* stylelint-disable-next-line no-descending-specificity */
    svg {
      color: var(--color-primary);
      border-radius: 100%;
      padding: 0.25rem;
      box-sizing: content-box;
      background-color: var(--color-secondary);
    }
  }
}

.workflowRecipientsFooter {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .workflowActionsFooter;

  border-radius: var(--item-radius);
  flex-direction: column;
  padding: var(--spacing-5);

  @include breakpoints.breakpoint-up(sm) {
    flex-direction: row;
    height: var(--item-size);
  }

  button {
    width: 100%;

    @include breakpoints.breakpoint-up(sm) {
      width: unset;
    }
  }

  button:first-of-type {
    margin-bottom: var(--button-spacing);

    @include breakpoints.breakpoint-up(sm) {
      margin-bottom: 0;
      margin-right: var(--button-spacing);
    }
  }
}

.historyGroup {
  line-height: 1.25;
  margin-bottom: var(--spacing-3);

  @include breakpoints.breakpoint-up(md) {
    line-height: unset;
    margin-bottom: unset;
  }
}

.cancelled {
  background-color: var(--color-warning-light);
}

.popover {
  white-space: pre-line;
  width: 27rem;
}

.form {
  position: relative;
}
