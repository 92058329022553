@use "../../../../../../../scss/utils/Breakpoints.module.scss" as breakpoints;

.collaborator {
  background: var(--color-white);
  margin-bottom: 1px;
  font-size: var(--font-size-sm);
  padding: 0.75rem;
  position: relative;
}

.columnData {
  margin-bottom: var(--spacing-4);

  @include breakpoints.breakpoint-up("md") {
    margin-bottom: 0;
  }
}

.notify {
  @include breakpoints.breakpoint-up("md") {
    text-align: center;

    // Set initial tooltip position to prevent the overlow-y in modalBody from adding the hidden scrollbar's width to the tooltip's placement
    position: relative;
  }
}

.action {
  text-align: left;
  color: var(--color-gray-600);
  display: flex;
  justify-content: left;

  svg {
    color: var(--color-gray-600);
  }

  @include breakpoints.breakpoint-up(md) {
    text-align: center;
    margin-bottom: 0;
    justify-content: center;
  }
}

.ownerIndicator {
  color: var(--color-gray-600);
  font-weight: normal;
  padding-left: var(--spacing-3);
}

.deleteCollaboratorButton {
  position: absolute;
  top: var(--spacing-5);
  right: 0;
  padding-right: var(--spacing-5);

  &:hover {
    svg {
      color: var(--color-primary);
    }
  }

  @include breakpoints.breakpoint-up(md) {
    text-align: right;
    position: unset;
    top: var(--spacing-5);
    justify-content: end;
    padding-right: 0;
  }
}
