.modal {
  width: 37.5rem;
}

.colBorder {
  border-bottom: 1px solid var(--color-gray-300);

  &:last-child {
    .colBorder {
      border-bottom: none;
    }
  }
}

.matterRow {
  &:first-child {
    padding-top: 0 !important;
  }

  &:last-child {
    .rightWrapper {
      border-bottom: none !important;
    }
  }
}

.matterTypeName {
  padding-bottom: 14px;
}

.buttonWrapper {
  gap: var(--button-spacing);
}
