.className {
  width: 100%;
}

.supportNumber {
  display: flex;
}

.supportNumberLink {
  text-decoration: none;
}
