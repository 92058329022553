@use "./Variables.module.scss";
@use "../utils/Breakpoints.module.scss" as breakpoints;

@mixin content {
  flex: 1 0;
  position: relative;
  overflow: auto;
  top: var(--bar-height);
  height: calc(100% - var(--bar-height));
  background-color: var(--color-gray-200);

  @include breakpoints.breakpoint-down(md) {
    padding-right: var(--spacing-4);
    padding-left: var(--spacing-4);
  }

  scrollbar-width: thin;
}
