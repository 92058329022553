.header {
  position: relative;
}

.tasksEmpty {
  text-align: center;
}

.emptyIcon {
  width: 3.75rem;
  height: 3.75rem;
}

.emptyText {
  margin-top: var(--spacing-5);
}

.content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-sm);
}

.empty {
  height: 100%;
}

.tabEmpty {
  height: calc(100% - 60px);
}

.optText {
  font-weight: var(--font-weight-normal);
}

.tabsNavWrapper {
  padding-left: var(--spacing-5);
  padding-right: var(--spacing-5);
}
