@use "../../../scss/generic/Variables.module.scss";

.className {
  user-select: none;
  cursor: pointer;
  padding: var(--spacing-5) 0;
  position: relative;
  height: 100%;
  display: flex;
  white-space: nowrap;

  svg {
    margin-right: var(--spacing-3);
  }

  &:not(:last-child) {
    margin-right: var(--spacing-8);
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: var(--color-tab-hover);
    left: 0;
    bottom: 0;
    transform: scaleX(0);
    transform-origin: 50% 0;
    transition: transform 0.25s ease-out;
  }

  &:hover {
    &::after {
      transform: scaleX(1);
      background-color: var(--color-tab-hover);
    }
  }
}

.active {
  font-weight: var(--font-weight-medium);

  &::after {
    background-color: var(--color-primary);
    transform: scaleX(1);
  }

  &:hover {
    &::after {
      background-color: var(--color-primary);
      transform: scaleX(1);
      transform-origin: 50% 0;
    }
  }
}
