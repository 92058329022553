@use "../../scss/generic/Variables.module.scss";
@use "../../scss/utils" as *;

.className {
  min-height: 100vh;
  background-image: linear-gradient(90deg, #106677 0%, #0b4d65 50%, #0c344d 100%);
  background-size: cover;
  position: relative;
}

.backdrop {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  background-image: url("../../images/backgrounds/backdrop01.jpg");
  background-size: cover;

  &.loaded {
    opacity: 1;
  }
}

.logo {
  pointer-events: none;
  margin: 0 auto;
  display: block;
  width: 180px;
  user-select: none;
  padding-bottom: var(--spacing-8);
  margin-top: calc(var(--spacing-8) * -1);
  @media (max-height: 650px) {
    padding-top: var(--spacing-6);
    padding-bottom: var(--spacing-6);
    margin-top: 0;
  }
}

.large {
  max-width: 700px;
}

.medium {
  max-width: 500px;
}

.small {
  max-width: 350px;
}

.loginBoxCenter {
  position: relative;
  z-index: 1;
  width: 100%;
  min-width: 280px;
  height: 100%;
  min-height: 100vh;
  align-content: center;
  padding-bottom: var(--spacing-8);
  @media (min-height: 499px) {
    padding-top: var(--spacing-6);
    padding-bottom: var(--spacing-6);
  }
}

.formBox {
  width: 100%;
}
