@use "../generic/Variables.module.scss";

.react-date-picker {
  display: inline-flex;
  position: relative;
  border: 2px solid var(--color-gray-300);
  border-radius: var(--radius-medium);
  padding: 0.5rem 0.3125rem;
  box-sizing: border-box;
  background-color: white;

  * {
    box-sizing: border-box;

    &::before,
    &::after {
      box-sizing: border-box;
    }
  }
}

.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &:invalid {
    background: rgb(255 0 0 / 10%);
  }
}

.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 0 6px;

  &:last-child {
    padding-right: 0;
  }

  &:enabled {
    cursor: pointer;

    &:disabled .react-date-picker__button__icon {
      stroke: var(--border-color);
    }

    &:hover .react-date-picker__button__icon,
    &:focus .react-date-picker__button__icon {
      stroke: #0078d7;
    }
  }

  svg {
    display: inherit;
  }
}

.react-date-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
  cursor: not-allowed;

  .react-date-picker__button,
  .react-date-picker__inputGroup__input {
    cursor: not-allowed;
  }
}

.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
}

.react-date-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  flex-grow: 1;
  padding: 0;
  box-sizing: content-box;
}

.react-date-picker__inputGroup__divider {
  padding: 0;
  white-space: pre;
}

.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}

.react-date-picker__calendar {
  width: 350px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 3;
}

.react-date-picker__calendar--closed {
  display: none;
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid var(--border-color);
  border-radius: var(--radius-small);
  font-family: inherit;
  line-height: 1.125em;
  box-sizing: border-box;

  * {
    box-sizing: border-box;

    &::before,
    &::after {
      box-sizing: border-box;
    }
  }

  button {
    margin: 0;
    border: 0;
    outline: none;

    &:enabled:hover {
      cursor: pointer;
    }
  }
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;

  /* stylelint-disable-next-line no-descending-specificity */
  button {
    min-width: 44px;
    background: none;

    /* stylelint-disable-next-line no-descending-specificity */
    &:enabled {
      &:hover,
      &:focus {
        background-color: #e6e6e6;
      }
    }

    &[disabled] {
      background-color: #f0f0f0;
    }
  }
}

.react-date-picker__calendar .react-calendar {
  border-width: thin;
}

.react-calendar--doubleView {
  width: 700px;

  .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;

    > * {
      width: 50%;
      margin: 0.5em;
    }
  }
}

.react-calendar__navigation__arrow {
  font-size: 23px;
}

.react-calendar__navigation__prev2-button {
  border-bottom-right-radius: var(--radius-small);
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__label,
.react-calendar__navigation__next-button {
  border-radius: 0 0 var(--radius-small) var(--radius-small);
}

.react-calendar__navigation__next2-button {
  border-bottom-left-radius: var(--radius-small);
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  color: var(--color-gray-600);

  abbr {
    text-decoration: none;
  }
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days__day--weekend {
  color: var(--color-gray-600);
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

/* stylelint-disable-next-line no-descending-specificity */
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
  border-radius: var(--radius-small);

  &:disabled {
    background-color: #f0f0f0;
  }

  &:enabled {
    &:hover,
    &:focus {
      background-color: #e6e6e6;
    }
  }
}

.react-calendar__tile--now {
  background: #ffff76;

  &:enabled {
    &:hover,
    &:focus {
      background: #ffffa9;
    }
  }
}

.react-calendar__tile--hasActive {
  background: #76baff;

  &:enabled {
    &:hover,
    &:focus {
      background: #a9d4ff;
    }
  }
}

.react-calendar__tile--active {
  background: var(--color-primary);
  color: white;

  &:enabled {
    &:hover,
    &:focus {
      background: #1087ff;
    }
  }
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.hidden {
  display: none;
}
