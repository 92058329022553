@use "../../../scss/utils/Breakpoints.module.scss" as breakpoints;

.className {
  position: fixed;
  bottom: 1.25rem;
  right: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--color-white);
  background: var(--color-primary);
  border-radius: 2.5rem;
  padding: 0.438rem 1rem 0.438rem 0.438rem;
  user-select: none;
  min-width: 6.875rem;
  box-shadow: rgb(0 0 0 / 18%) 0 25.6px 57.6px 0, rgb(0 0 0 / 16%) 0 4.8px 14.4px 0;
  z-index: var(--z-index-support);

  @include breakpoints.breakpoint-up(sm) {
    right: 1.5rem;
  }

  &:active {
    background: var(--color-primary-dark);
  }
}

.animate {
  transition: var(--transition-base);
  min-width: 0;
  padding: 0.438rem;

  &:hover {
    padding: 0.438rem 1rem 0.438rem 0.438rem;
    min-width: 6.875rem;
  }

  .supportText {
    display: none;
  }

  &:hover .supportText {
    display: block;
  }
}

.supportIcon {
  background: var(--color-white);
  border-radius: 50%;
  padding: 0.188rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
