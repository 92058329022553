@use "../../scss/generic/Variables.module.scss";

.className {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(0 0 0 / 20%);
  z-index: var(--z-index-backdrop-spinner);
}
