@use "../../../scss/generic/Variables.module.scss";

.className {
  border-bottom: 1px solid var(--border-color);
}

.className:last-child {
  border-bottom: none;
}

.link {
  width: 100%;
  text-decoration: none;
  color: var(--color-gray-800) !important;
  padding: 10px 0;
  display: block;
  font-weight: normal;
  font-size: var(--font-size-base);

  svg {
    color: var(--color-primary);
  }
}

.link:hover {
  color: var(--color-link-hover) !important;

  svg {
    color: var(--color-link-hover);
  }
}

.inaccessible {
  opacity: 0.5;

  &:hover {
    color: var(--color-gray-900) !important;
  }
}

.denied,
.disabled {
  opacity: 0.5;
  cursor: not-allowed;

  &:hover {
    color: var(--color-gray-900) !important;
  }
}
