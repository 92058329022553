@use "../../../../../../scss/utils/Breakpoints.module.scss" as breakpoints;

.className {
  border-radius: var(--radius-medium);
  height: 100%;

  &:not(:last-child) {
    margin-bottom: var(--grid-column-gutter-md);
  }
}

.taskPaneSection {
  height: auto;
  min-height: auto;
  padding-bottom: var(--grid-column-gutter-md);
  background-color: transparent;

  @include breakpoints.breakpoint-up(md) {
    height: 50%;
    min-height: 240px;
  }
}

.header {
  background: var(--color-accent);
  color: var(--color-white);
  border-radius: var(--radius-medium) var(--radius-medium) 0 0;
  height: var(--bar-height);
  display: flex;
  align-content: center;
}

.content {
  overflow-y: auto;
  border-radius: 0 0 var(--radius-medium) var(--radius-medium);
  height: calc(100% - var(--bar-height));
  background-color: var(--color-white);
}
