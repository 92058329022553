@use "../../../../../scss/generic/Variables.module.scss";

.text {
  font-size: var(--font-size-sm);
  color: var(--color-gray-600);
  white-space: initial;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.25;
}

.noWrap {
  flex-wrap: nowrap;
}
