@use "../../scss/generic/Variables.module.scss";

.className {
  height: auto;
}

.tabsNavWrapper {
  height: 60px;
  display: flex;
  position: relative;
  background-color: var(--color-white);
  align-items: flex-end;
  overflow-x: auto;
  padding: 0 var(--spacing-6);
  z-index: 3; // to prevent sticky table-header from overlapping
  border-bottom: 1px solid var(--border-color);
}

.sticky {
  position: sticky;
  top: 0;
}

.tabsNavWrapperSmall {
  .tabsNavWrapper {
    padding: 0 var(--spacing-5) !important;
  }
}
