@use "../../../../scss/mixins/breakpoints.module.scss" as breakpoints;

.userDetailsWrapper {
  padding-right: 20px;
  padding-left: 20px;

  @include breakpoints.media-lg {
    display: none;
  }
}
