.className {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3cline x1='0' y1='100%25' x2='100%25' y2='100%25' fill='none' stroke='%23cdcecf' stroke-width='1' stroke-dasharray='6%2c 9' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  padding-top: var(--spacing-5);
  padding-bottom: var(--spacing-5);

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    background-image: none;
  }
}

.action {
  font-size: var(--font-size-sm);
}

.buttonWrapper {
  gap: 0.5rem;
}
