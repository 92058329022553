@use "../../../scss/generic/PageContent.module.scss" as pagecontent;
@use "../../../scss/utils/Breakpoints.module.scss" as breakpoints;

.pageContent {
  @include pagecontent.content;

  padding: 0 var(--container-px);
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.scrollingColumnsContainer {
  position: relative;

  @include breakpoints.breakpoint-up(lg) {
    height: calc(100% - var(--bar-height) - var(--breadcrumb-height));
  }
}

.backBtn {
  display: inline-flex;
  align-items: center;
  text-decoration: none;

  button {
    background: var(--color-white);
  }

  span {
    display: inline-block;
    margin-left: var(--spacing-4);
  }
}

.leftColumn {
  border-radius: var(--radius-medium);
  background: var(--color-white);
  overflow-y: auto;
  min-height: 280px;
  height: 100%;
}

.editMatterModal {
  max-width: 34.375rem;
}

.interfaceWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: var(--color-white);
}
