.background {
  padding: 0 var(--spacing-6);
}

.type {
  margin-bottom: 0;
  font-weight: var(--font-weight-normal);
}

.description {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.headerGrid {
  padding-bottom: var(--spacing-6);
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 0.8rem;
  font-size: var(--font-size-md);
}

.showSignedWrapper {
  display: flex;
  justify-content: flex-end;
  min-height: 3.375rem;
  margin-bottom: var(--spacing-5);
  padding: var(--spacing-5) 0 var(--spacing-5);
}
