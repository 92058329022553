@use "../../../../../scss/generic/Variables.module.scss";

.primaryDocLink {
  display: grid;
  text-align: left;
  padding: var(--spacing-4) var(--spacing-5);
  line-height: 1.25;
}

.primaryDocLinkType {
  color: var(--color-gray-900);
}

.primaryDocLinkDescription {
  display: block;
  color: var(--color-primary) !important;
  font-weight: var(--font-weight-medium);
  padding-top: var(--spacing-3);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
