@use "../../../scss/generic/Variables.module.scss";
@use "../../../scss/utils/Breakpoints.module.scss" as breakpoints;
@use "../../../scss/generic/PageContent.module.scss" as pagecontent;

.pageContent {
  @include pagecontent.content;
}

.actionBar {
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: var(--color-white);
  height: var(--bar-height);
  padding: 0 var(--container-px);

  @include breakpoints.breakpoint-down(md) {
    padding: var(--spacing-5);
  }
}

.interfaceWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: var(--color-gray-200);
}

.group {
  @include breakpoints.breakpoint-down(md) {
    line-height: 1.25;
    margin-bottom: 0.25rem;
  }
}

.tableCardContainer {
  position: relative;
  top: 0;
}

.filterButtonColumn {
  text-align: right;
}

.noWrap {
  white-space: nowrap;
}
