.className {
  position: relative;
  display: inline-block;
}

.fullWidthClassName {
  position: relative;
  display: block;
}

textarea[disabled] {
  cursor: not-allowed;
}

.charCount {
  position: absolute;
  right: 0;
  bottom: -1.5rem;
}

.error {
  color: var(--color-red);
}
