.className {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  width: 90%;
  color: var(--color-gray-500);
  margin-bottom: 10px;
}

hr {
  margin: 0.5rem 0;
}
