@use "../../../../../scss/utils/Breakpoints.module.scss" as breakpoints;

.className {
  overflow-y: auto;
  height: 100%;
  padding-left: 0;

  @include breakpoints.breakpoint-up(md) {
    padding-left: var(--grid-column-gutter-md);
  }
}
