.className {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow: hidden;
  max-height: 100%;
}

.right {
  justify-content: flex-end;
}
