.regular {
  width: 3rem;
  height: 3rem;
  background: var(--color-white);
  border-radius: 50%;
  border: calc(var(--border-width) * 2) solid var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.solid {
  width: 1.1rem;
  height: 1.1rem;
  background-color: var(--color-success);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: var(--color-white);
  }
}
