@use "../../../scss/generic/Variables.module.scss";
@use "../../../scss/mixins/breakpoints.module.scss";
@use "../../../scss/utils/Grid.module.scss";

.container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.fullViewportHeight {
  min-height: 100vh;
}
