@use "../../scss/generic/Variables.module.scss";

.className {
  background-color: var(--color-gray-200);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textPrimary {
  color: var(--color-primary);
}

.title {
  white-space: pre-line;
  margin-bottom: var(--spacing-5);
  padding-top: var(--spacing-5);
}
