@use "../../../scss/utils/Breakpoints.module.scss" as breakpoints;

.header {
  position: sticky;
  top: 0;
  z-index: var(--z-index-navbar);
  background: var(--color-navy);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--bar-height);
  padding: 0 var(--navbar-px);
  color: var(--color-white);
}

.urnLabel {
  color: var(--color-gray-500);
  margin-left: var(--spacing-5);
}

.content {
  padding-top: var(--spacing-8);
  width: 100%;
  max-width: 90%;
  margin: 0 auto;

  @include breakpoints.breakpoint-up(sm) {
    max-width: 95%;
  }

  @include breakpoints.breakpoint-up(xl) {
    max-width: 60%;
  }
}

.input {
  border-bottom: 1px solid var(--border-color);
  padding: var(--spacing-5) 0;
  width: 100%;
}

.question {
  display: block;
  font-weight: var(--font-weight-medium);
  line-height: 1.25;
  margin-bottom: 0.75rem;
  width: 100%;
}

.response {
  background: var(--color-tab-active);
  padding: 0.5rem 1.25rem 0.5rem 2.2rem;
  border-radius: var(--radius-small);
  display: inline-block;
  word-break: break-word;
  position: relative;

  &::before {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2300828a'%3E%3Cpath d='M10.635 15.368 7.646 12.38l.708-.707 2.284 2.283 5.009-4.973.706.71z'/%3E%3C/svg%3E");
    height: 2.5rem;
    left: -0.6rem;
    padding-left: var(--spacing-4);
    position: absolute;
    top: 0;
    width: 3rem;
  }
}
