.userName {
  font-size: var(--font-size-sm);
  line-height: 1rem;
}

.userRole {
  font-size: var(--font-size-xs);
  color: var(--color-gray-600);
  line-height: 1rem;
}
