@use "../../scss/generic/Variables.module.scss";
@use "../../scss/utils/Breakpoints.module.scss" as breakpoints;

.className {
  min-height: 100vh;
  width: 1px;
  position: relative;
  background: white;
  transition: margin 0.3s ease-in-out;
  transform: translateX(0);
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 10%);
  z-index: var(--z-index-drawer);
  overflow: visible;

  @include breakpoints.breakpoint-down(md) {
    position: fixed;
  }
}

.left {
  border-right: 1px solid var(--border-color);
}

.right {
  border-left: 1px solid var(--border-color);
}

.closeBtn {
  position: fixed;
  top: 0.875rem;
  right: -1rem;
  background: var(--color-white);
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 10%);
  border: 1px solid var(--border-color);

  @include breakpoints.breakpoint-up(lg) {
    display: none;
  }
}

// Sidebar navigation menu

.drawer {
  top: var(--bar-height);
  height: 100%;
  padding-bottom: var(--bar-height);
  font-size: var(--font-size-sm);
  background-color: var(--color-gray-200);
  position: fixed;

  @include breakpoints.breakpoint-up(lg) {
    position: relative;
  }
}

.openDrawerBtn {
  margin-right: 1rem;
  display: block;

  @include breakpoints.breakpoint-up(lg) {
    display: none;
  }
}

.sidebarNavLinkGroup {
  padding: var(--spacing-5) var(--spacing-4) var(--spacing-5) 0;

  &:first-child {
    padding-top: 0;
  }

  h6 {
    padding-left: var(--container-px);
  }
}

.sidebarNavMenu {
  font-size: var(--font-size-sm);
  padding-left: 0;

  ul {
    padding-inline-start: 0;
  }

  li {
    list-style: none;
    position: relative;
    padding: var(--spacing-4) var(--spacing-5) var(--spacing-4) calc(var(--container-px) + var(--spacing-4));
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;

    &::after {
      content: "";
    }
  }

  a {
    text-decoration: none;
  }
}

.link {
  cursor: pointer;
  user-select: none;
  margin-bottom: var(--spacing-3);

  &.active {
    background-color: var(--color-tab-active);
    font-weight: var(--font-weight-medium);
    transition: var(--transition-base);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 3px;
      background-color: var(--color-primary);
    }
  }

  a {
    color: inherit;
  }
}

.sidebarInner {
  padding-top: calc(var(--bar-height) / 2);
  padding-right: var(--spacing-5);
  position: relative;
  top: 0;
  height: 100%;
  overflow-y: auto;
  padding-bottom: var(--spacing-8);
}
