@use "../../../../scss/generic/Variables.module.scss";
@use "../../../../scss/utils/Breakpoints.module.scss" as breakpoints;

.className {
  vertical-align: text-top;
  font-size: var(--font-size-sm);
  padding: var(--spacing-4) var(--spacing-5);
  flex-grow: 1;

  @include breakpoints.breakpoint-down(md) {
    padding: var(--spacing-1) var(--spacing-5);

    &:last-of-type {
      position: absolute;
      top: var(--spacing-5);
      right: 0;
      text-align: right;
    }
  }

  @include breakpoints.breakpoint-up(xl) {
    padding: var(--spacing-4) var(--spacing-5);
  }
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.overflow {
  overflow: visible;
}

.tableCardItem {
  position: relative;
  border-bottom: 1px solid var(--border-color);
  padding: var(--spacing-4) 0;
  align-items: center;
  background-color: var(--color-white);

  @include breakpoints.breakpoint-down(md) {
    border-bottom: none;
    margin-bottom: var(--spacing-3);
    padding-top: var(--spacing-5);
    padding-bottom: var(--spacing-5);
    border-radius: var(--radius-small);
  }

  @include breakpoints.breakpoint-up(md) {
    height: 52px;
    flex-wrap: nowrap;
  }
}

.actionsMenuColumn {
  text-align: right;
}

.timeline {
  .className {
    border-right: 1px solid var(--border-color);
    padding: var(--spacing-4) var(--spacing-5);
    align-items: center;

    &:first-of-type {
      justify-content: center;
    }

    &:last-of-type {
      border-right: none;
    }

    @include breakpoints.breakpoint-down(lg) {
      padding: var(--spacing-4);
    }

    @include breakpoints.breakpoint-down(md) {
      border-right: none;
      display: block;
    }
  }

  .tableCardItem {
    margin-top: 2px;
    padding: 0;
    border-bottom: 1px solid var(--border-color);
    align-items: stretch;
  }
}

.clickable {
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: var(--color-gray-100);
  }

  &:active {
    background-color: var(--color-gray-200);
  }
}
