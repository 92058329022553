@use "sass:color";
@use "sass:list";
@use "sass:map";
@use "sass:math";
@use "sass:meta";
@use "sass:string";
@use "../generic/Variables.module.scss" as *;
@use "Functions.module" as functions;

// Utilities

$utilities: () !default;

// Include !important to generated utilities to ensure they override components and modifier classes as intended.

$enable-important-utilities: true !default;

// Prefix for :root CSS variables

$variable-prefix: -- !default;

// stylelint-disable-next-line scss/dollar-variable-default
$spacer: var(--spacing-base) !default;
$spacers: (
  0: 0,
  1: var(--spacing-1),
  2: var(--spacing-2),
  3: var(--spacing-3),
  4: var(--spacing-4),
  5: var(--spacing-5),
  6: var(--spacing-6),
  7: var(--spacing-7),
  8: var(--spacing-8),
) !default;

// Grid breakpoints for media queries

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

@include functions.assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include functions.assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// Grid containers

$container-max-widths: (
  sm: 500px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
) !default;

@include functions.assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns

$grid-columns: 12 !default;
$grid-gutter-width: var(--grid-column-gutter) !default;
$grid-row-columns: 6 !default;
$gutters: $spacers !default;

// Container padding

$container-padding-x: calc(var(--grid-column-gutter) * 0.5) !default;

// Theme colors map
$theme-colors: (
  primary: var(--color-primary),
  secondary: var(--color-secondary),
  success: var(--color-success),
  info: var(--color-info),
  warning: var(--color-warning),
  error: var(--color-error),
  light: var(--color-light),
  medium: var(--color-medium),
  dark: var(--color-dark),
  white: var(--color-white),
) !default;
