.matterTask {
  padding: var(--spacing-5) 0;
  background-color: white;
  border-bottom: var(--border-width) var(--border-color) solid;

  &:last-child:not(:first-child) {
    border-bottom: none;
  }
}

.name {
  font-size: var(--font-size-sm);
  flex-wrap: wrap;
  position: relative;
}

.description {
  font-size: var(--font-size-sm);
  color: var(--color-gray-800);
  word-break: break-word;
}

.multiUseIcon {
  position: absolute;
  top: -0.25rem;
  left: -1.22rem;

  svg {
    max-width: 1rem;
    max-height: 1rem;
    min-width: 1rem;
    min-height: 1rem;
  }
}

.assignee {
  line-height: normal;
  font-size: var(--font-size-sm);

  svg {
    transform: scale(0.8);
    color: var(--color-gray-600);
  }

  &.disabled {
    color: var(--color-gray-600);
    opacity: 1;

    svg {
      color: var(--color-gray-600);
    }
  }
}

.btnWrapper {
  gap: var(--spacing-3);
  flex-wrap: wrap;
}
