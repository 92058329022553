@use "../../../../scss/utils/Breakpoints.module.scss" as breakpoint;

.actionBar {
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  height: var(--bar-height);
  padding: 1rem;

  @include breakpoint.breakpoint-up(md) {
    display: none;
  }
}
