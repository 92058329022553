.wrapper {
  color: var(--color-gray-500);
  text-align: center;
}

.typography {
  font-style: italic;
}

.icon {
  color: inherit;
  width: 3.75rem;
  height: 3.75rem;
}
