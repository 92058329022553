.className {
  position: relative;

  svg {
    display: block;
  }

  &::after {
    position: absolute;
    bottom: -95%;
    right: -95%;
    width: 30px;
    height: 30px;
    content: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ccircle fill='%2306c778' cx='5' cy='5' r='5'/%3E%3Cpolygon fill='%23ffffff' points='4.1,7.3 2.3,5.5 3,4.8 4.1,5.9 6.8,3.3 7.5,4 '/%3E%3C/svg%3E");
  }
}
