@use "../../../scss/generic/Variables.module.scss";

.className {
  display: flex;
  align-items: center;
}

.bar {
  width: 88%;
  height: 0.3rem;
  background: rgb(0 0 0 / 10%);
  border-radius: 0.125rem;
  margin-right: 8px;
  position: relative;
}

.barInner {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  border-radius: inherit;
  transition: var(--transition-base);

  &.weak {
    width: 33%;
    background: var(--color-error);
  }

  &.good {
    width: 66%;
    background: var(--color-warning);
  }

  &.strong {
    width: 100%;
    background: var(--color-success);
  }
}

.text {
  color: var(--color-error);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);

  &.weak {
    color: var(--color-error);
  }

  &.good {
    color: var(--color-warning);
  }

  &.strong {
    color: var(--color-success);
  }
}
