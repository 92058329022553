@use "sass:color";
@use "sass:list";
@use "sass:map";
@use "sass:math";
@use "sass:meta";
@use "sass:string";
@use "../generic/Variables.module.scss";
@use "SassVariables.module" as *;
@use "Functions.module" as functions;
@use "Mixins.module" as *;
@use "Breakpoints.module" as *;

// Row (contains columns)

.row {
  /* stylelint-disable-next-line scss/at-mixin-argumentless-call-parentheses */
  @include make-row();

  > * {
    /* stylelint-disable-next-line scss/at-mixin-argumentless-call-parentheses */
    @include make-col-ready();
  }
}

// Columns

/* stylelint-disable-next-line scss/at-mixin-argumentless-call-parentheses */
@include make-grid-columns();

// Containers

// Single container class with breakpoint max-widths
.container,
  // 100% wide container at all breakpoints
  .container-fluid {
  /* stylelint-disable-next-line scss/at-mixin-argumentless-call-parentheses */
  @include make-container();
}

// Responsive containers that are 100% wide until a breakpoint
@each $breakpoint, $container-max-width in $container-max-widths {
  .container-#{$breakpoint} {
    /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
    @extend .container-fluid;

    max-width: $container-max-width;
  }

  @include breakpoint-up($breakpoint, $grid-breakpoints) {
    %responsive-container-#{$breakpoint} {
      max-width: $container-max-width;
    }

    // Extend each breakpoint which is smaller or equal to the current breakpoint
    $extend-breakpoint: false;

    @each $name, $width in $grid-breakpoints {
      /* stylelint-disable-next-line scss/at-rule-conditional-no-parentheses */
      @if ($extend-breakpoint) {
        .container#{breakpoint-infix($name, $grid-breakpoints)} {
          @extend %responsive-container-#{$breakpoint};
        }

        // Once the current breakpoint is reached, stop extending
        /* stylelint-disable-next-line scss/at-rule-conditional-no-parentheses */
        @if ($breakpoint == $name) {
          $extend-breakpoint: false;
        }
      }
    }
  }
}
