@use "../../../scss/generic/Spacing.module.scss";

.className {
  width: 100%;
  border-bottom: 1px solid var(--border-color);
}

.headerWrapper {
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: var(--spacing-base) 12px var(--spacing-base) 12px;
  cursor: pointer;
}

.contents {
  overflow: visible;
  transition: height 0.2s linear;
  width: 100%;
  padding: var(--spacing-4) var(--spacing-base) var(--spacing-6);
}

.icon {
  transition: var(--transition-base);
  display: flex;
  justify-content: end;
  align-items: center;
  border: none;
  background: none;
  padding: 0;

  &:hover {
    svg {
      color: var(--color-primary);
    }
  }

  :active {
    background: none;
  }
}

.icon.expanded {
  transform: rotate(180deg);
  transform-origin: center;
}
