@use "../../../scss/generic/Variables.module.scss";
@use "../../../scss/utils/Breakpoints.module.scss" as breakpoints;

:root {
  --font-size: 0.75rem;
}

.className {
  font-size: var(--font-size);
  padding: var(--spacing-4) var(--spacing-5);
  text-transform: uppercase;
  border-bottom: 1px solid var(--border-color);

  // prevent form elements in header from inheriting header styles
  label {
    text-transform: none;
    font-size: initial;
    font-weight: normal;
  }

  &:first-of-type {
    padding-left: 1rem;
  }

  @include breakpoints.breakpoint-down(md) {
    display: none;
  }

  @include breakpoints.breakpoint-up(md) {
    flex-grow: 1;
  }
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.button {
  font-size: var(--font-size);
  color: var(--color-gray-600);
}
