/* stylelint-disable font-family-name-quotes */
// Thin
@font-face {
  font-family: "UI Roboto";
  font-style: normal;
  font-weight: 100;
  src: url("../../fonts/roboto/Roboto-Thin.ttf");
}

// Thin Italic
@font-face {
  font-family: "UI Roboto";
  font-style: italic;
  font-weight: 100;
  src: url("../../fonts/roboto/Roboto-ThinItalic.ttf");
}

// Light
@font-face {
  font-family: "UI Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/roboto/Roboto-Light.ttf");
}

// Light Italic
@font-face {
  font-family: "UI Roboto";
  font-style: italic;
  font-weight: 300;
  src: url("../../fonts/roboto/Roboto-LightItalic.ttf");
}

// Regular
@font-face {
  font-family: "UI Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/roboto/Roboto-Regular.ttf");
}

// Regular Italic
@font-face {
  font-family: "UI Roboto";
  font-style: italic;
  font-weight: 400;
  src: url("../../fonts/roboto/Roboto-Italic.ttf");
}

// Medium
@font-face {
  font-family: "UI Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("../../fonts/roboto/Roboto-Medium.ttf");
}

// Medium italic
@font-face {
  font-family: "UI Roboto";
  font-style: italic;
  font-weight: 500;
  src: url("../../fonts/roboto/Roboto-MediumItalic.ttf");
}

// Bold
@font-face {
  font-family: "UI Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/roboto/Roboto-Bold.ttf");
}

// Bold italic
@font-face {
  font-family: "UI Roboto";
  font-style: italic;
  font-weight: 700;
  src: url("../../fonts/roboto/Roboto-BoldItalic.ttf");
}

// Black
@font-face {
  font-family: "UI Roboto";
  font-style: normal;
  font-weight: 900;
  src: url("../../fonts/roboto/Roboto-Black.ttf");
}

// Black italic
@font-face {
  font-family: "UI Roboto";
  font-style: italic;
  font-weight: 900;
  src: url("../../fonts/roboto/Roboto-BlackItalic.ttf");
}

@font-face {
  font-family: "UI Roboto";
  font-style: italic;
  font-weight: 900;
  src: url("../../fonts/roboto/Roboto-BlackItalic.ttf");
}

$fontstack: "UI Roboto", system-ui, -apple-system, "Segoe UI", roboto, "Helvetica Neue", arial, "Noto Sans",
  "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
