@use "../../scss/generic/Variables.module.scss";

/* General class */
.className {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: calc(var(--button-py) - var(--border-width)) calc(var(--button-px) - var(--border-width));
  line-height: 1.5;
  border: none;
  font-size: var(--font-size-base);
  font-weight: var(--body-font-weight);
  cursor: pointer;
  transition: var(--transition-base);
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  user-select: none;
  text-decoration: none;

  &.disabled {
    cursor: auto;
    opacity: var(--disabled-opacity);
    pointer-events: auto;
  }

  .iconInternal {
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: middle;
  }
}

/* Variant square */
.square {
  width: 2.25rem;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0;

  &.small {
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
    padding: calc(var(--button-py-sm) - var(--border-width));
  }
}

/* Size small */
.small {
  padding: calc(var(--button-py-sm) - var(--border-width)) calc(var(--button-px-sm) - var(--border-width));
  font-size: var(--font-size-sm);
  border-radius: 0.2rem;

  .iconInternal {
    width: 1rem;
    height: 1rem;
    vertical-align: middle;
  }
}

/* Size large */
.large {
  padding: calc(var(--button-py-lg) - var(--border-width)) calc(var(--button-px-lg) - var(--border-width));
  font-size: var(--font-size-lg);
  border-radius: 0.3rem;
}

/* Color primary */
.primary {
  background-color: var(--color-primary);
  color: #fff;

  &:hover {
    background-color: var(--color-primary-medium);
  }

  &:active {
    background-color: var(--color-primary-dark);
  }

  &:focus {
    background-color: var(--color-primary-dark);
  }
}

.secondary svg {
  color: var(--color-primary);
}

.dark svg {
  color: var(--color-gray-900);
}

.light svg {
  color: var(--color-gray-600);
}

.outlined > svg {
  color: inherit !important;
}

.iconleft > svg {
  margin-right: 0.5rem;
}

.iconright > svg {
  margin-left: 0.5rem;
}

.iconSolo > svg {
  margin: 0;
}

.primary:not(.link) svg {
  color: #fff;
}

/* Color secondary */
.secondary {
  background-color: var(--color-gray-200);
  color: var(--color-primary-dark);

  &:hover {
    background-color: var(--color-gray-300);
    color: var(--color-primary-medium);

    svg {
      color: var(--color-primary-medium);
    }
  }

  &:active {
    background-color: var(--color-gray-300);
  }

  &:focus {
    background-color: var(--color-gray-300);
  }
}

.danger {
  background-color: var(--color-error-light);
  color: var(--color-error);

  &:hover {
    color: var(--color-error-dark);
  }

  &:active {
    background-color: var(--color-error-medium);
  }

  &:focus {
    background-color: var(--color-error-medium);
  }
}

/* Color dark */
.dark {
  background-color: var(--color-gray-200);
  color: var(--color-gray-900);

  &:hover {
    color: var(--color-gray-600);

    svg {
      color: var(--color-gray-600);
    }
  }

  &:active {
    background-color: var(--color-gray-600);
  }

  &:focus {
    background-color: var(--color-gray-600);
  }
}

/* Color light */
.light {
  background-color: var(--color-gray-200);
  color: var(--color-gray-600);

  &:hover {
    color: var(--color-gray-400);

    svg {
      color: var(--color-gray-400);
    }
  }

  &:active {
    background-color: var(--color-gray-300);
  }

  &:focus {
    background-color: var(--color-gray-300);
  }
}

/* Variant link */
.link {
  background: none;
  border: none;
  padding: 0;
  color: inherit;

  &:hover {
    background: none;
  }

  &:active {
    background: none;
  }

  &:focus {
    background: none;
  }
}

.link.primary {
  color: var(--color-primary);
}

.link.primary:hover {
  background-color: none;
}

.link.secondary:hover {
  background-color: none;
}

/* Variant round */
.round {
  border-radius: 100%;
  padding: 0.25rem;
  width: 2.25rem;
  height: 2.25rem;
}

/* Variant outlined */
.outlined {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.outlined.dark {
  border-color: var(--color-gray-300);
  color: var(--color-gray-900);

  &:hover {
    background-color: var(--color-gray-100);
    color: var(--color-gray-900);
  }
}

.outlined.primary {
  color: var(--color-primary);
  border-color: var(--color-primary);

  &:hover {
    background-color: initial;
    color: var(--color-primary-light);
    border-color: var(--color-primary-light);
  }
}

.outlined.secondary {
  color: var(--input-border-color);
  border-color: var(--input-border-color);

  &:hover {
    background-color: initial;
    color: var(--color-primary-dark);
    border-color: var(--input-border-color);
  }
}

.icon.large .iconInternal {
  width: calc(1.5em * var(--font-size-lg));
}
