@use "../../scss/generic/PageContent.module.scss" as pagecontent;
@use "../../scss/utils/Breakpoints.module.scss" as breakpoints;

.pageContent {
  @include pagecontent.content;

  padding: 0 var(--container-px);
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.interfaceWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: var(--color-white);
}

.notFoundContent {
  height: 100%;
  margin: 0 auto;

  @include breakpoints.breakpoint-up("md") {
    height: 85%; // To achieve optical middle
  }
}
