@use "../generic/Variables.module.scss";

.SelectAutocomplete__control[class*="-control"] {
  border-radius: var(--radius-small);
  border-color: var(--input-border-color);
  min-height: 36px;
}

.SelectAutocomplete__input-container[class*="-container"] {
  padding-top: 0;
  padding-bottom: 0;
}

.SelectAutocomplete__control--is-focused[class*="-control"],
.SelectAutocomplete__control--is-focused[class*="-control"]:hover {
  box-shadow: none;
  border-color: var(--input-border-color-focus);
}

.SelectAutocomplete__value-container[class*="-ValueContainer"] {
  padding: 3px 8px;
}

.SelectAutocomplete__option--is-focused[class*="-option"] {
  background-color: var(--color-hover);
}

.SelectAutocomplete__option--is-focused[class*="-option"]:active {
  background-color: var(--color-primary-medium);
}

.SelectAutocomplete__option--is-selected[class*="-option"] {
  background-color: var(--color-primary-medium);
  color: white;
}

.SelectAutocomplete__indicator[class*="-indicatorContainer"] {
  color: var(--color-primary);
}

.SelectAutocomplete--is-disabled .SelectAutocomplete__indicator[class*="-indicatorContainer"] {
  color: var(--color-gray-400);
}

.SelectAutocomplete__menu-list[class*="-MenuList"] {
  padding: 0;
  max-height: 250px;
}

.autoWidth {
  display: inline-block;
  min-width: 5.5rem;
}

.hidden {
  display: none;
}
