:root {
  --dimensions: 1.75rem;
  --positioning: 0;
  --border-width: 1px;
}

.className {
  position: absolute;
  z-index: calc(var(--z-index-select) + 1);
  top: 0.25rem;
  right: 0.25rem;
  left: var(--positioning);
  margin: 0 0 auto auto;
  width: var(--dimensions);
  height: var(--dimensions);
  font-size: var(--font-size-xs);
}

.clearableField {
  padding-right: calc(var(--dimensions) + var(--button-py));
}
