.className {
  display: flex;
  padding: var(--spacing-5);

  ul {
    margin: 0;
  }
}

.info {
  background: var(--color-info-light);
}

.warning {
  background: var(--color-warning-light);
}

.icon {
  margin-right: var(--spacing-4);
}
