@use "../../../../scss/utils/Breakpoints.module.scss" as breakpoint;

.popover {
  padding: 0;
}

// clip scrollbar inside element
.popoverContentClip {
  overflow: hidden;
  border-radius: var(--popover-border-radius);
}

.popoverContents {
  max-width: 300px;
  max-height: 200px;
  overflow-y: auto;
  padding: var(--spacing-5) var(--spacing-7) var(--spacing-5) var(--spacing-5);
}

.notifiedButton {
  position: relative;
  width: auto;

  * {
    display: inline-flex;
    align-items: center;
  }

  @include breakpoint.breakpoint-up(md) {
    width: 100%;
  }
}

.numOfRecipients {
  padding-left: 0.25rem;
  color: var(--color-gray-800);
  font-size: var(--font-size-xs);
}
