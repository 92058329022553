@use "../../../scss/generic/Variables.module.scss";
@use "../../../scss/utils/Breakpoints.module.scss" as breakpoint;
@use "../../../scss/generic/PageContent.module.scss" as pagecontent;

.className {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.interfaceWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: var(--color-gray-200);
}

.sidebarInner {
  scrollbar-gutter: stable;
  scrollbar-width: thin;
}

.pageContent {
  @include pagecontent.content;

  // Change background-color for mobile table cards
  @include breakpoint.breakpoint-up(md) {
    background-color: var(--color-white);
  }
}

.reportContainer {
  @include breakpoint.breakpoint-up(lg) {
    padding: 0 var(--container-px);
  }
}

// Append/modify the style of the Drawer component
.drawer {
  top: var(--bar-height);
  height: 100%;
  padding-bottom: var(--bar-height);
  font-size: var(--font-size-sm);
  background-color: var(--color-gray-200);
  position: fixed;

  @include breakpoint.breakpoint-up(lg) {
    position: relative;
  }
}

.accordionItem {
  padding-right: 0;
  padding-left: 0;
}

.accordionHeader {
  padding-left: 2.5rem !important;
}

.accordionContents {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.group {
  line-height: 1.25;
  margin-bottom: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include breakpoint.breakpoint-up(md) {
    line-height: initial;
    margin-bottom: 0;
  }
}

.actionBar {
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  padding: var(--spacing-6) 0;

  @include breakpoint.breakpoint-down(md) {
    padding-top: 1rem !important;
    padding-left: 1rem;
  }

  h4 {
    margin-bottom: 0;
  }
}

.tableContainer {
  position: relative;
  top: 0;
  min-height: 100vh;
}

.searchWrapper {
  flex: 0 1 auto;
}

.openDrawerBtn {
  margin-right: 1rem;
  flex: 1 0 auto;
  display: block;

  @include breakpoint.breakpoint-up(lg) {
    display: none;
  }
}

.emptyStateWrapper {
  color: var(--color-gray-600);
  text-align: center;
}

.emptyStateIcon {
  color: var(--color-gray-400);
  width: 3.75rem;
  height: 3.75rem;
}

.label {
  font-weight: var(--font-weight-medium);
  padding-bottom: 0.825rem;
  display: block;
  padding-right: var(--spacing-8);

  @include breakpoint.breakpoint-up(xl) {
    padding-right: 0;
  }
}

.labelWithPopover {
  font-weight: var(--font-weight-medium);
  padding-bottom: 0.825rem;

  * {
    display: inline-block;
  }

  .label {
    font-weight: var(--font-weight-medium);
  }
}

.formRowHeader {
  padding: var(--spacing-7) 0 var(--spacing-5) 0;
}

.formRow {
  border-bottom: var(--border-width) solid var(--border-color);
  padding: var(--spacing-6) 0;

  &.justified {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {
      display: unset;
      font-size: initial;
      font-weight: var(--font-weight-medium);
      padding-bottom: 0;
    }
  }
}

.popover {
  white-space: pre-line;
  width: 27rem;
  font-weight: var(--font-weight-normal);
}

.borderBox {
  border: var(--border-width) solid var(--border-color);
  padding: var(--spacing-5);
  border-radius: var(--radius-small);
}

.scrollBox {
  max-height: 16.25rem;
  overflow-y: auto;
  border: var(--border-width) solid var(--border-color);
  padding: var(--spacing-5);
  border-radius: var(--radius-small);
}

.hidden {
  display: none;
}

.analyticsTableContainer {
  margin-top: var(--bar-height);
  padding: 0;
}

.tableWrapper {
  padding: 0;
}
