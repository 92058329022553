@use "../../scss/utils/Breakpoints.module.scss" as breakpoints;
@use "sass:math";

.className {
  table-layout: fixed;
  width: 100%;

  tr {
    @include breakpoints.breakpoint-down(md) {
      border-bottom: 0;
      display: flex;
      flex-direction: row;
      /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
      flex-wrap: wrap;
    }

    td {
      vertical-align: middle;
      @include breakpoints.breakpoint-down(md) {
        width: calc(100% - 100px) !important;
        max-width: calc(100% - 100px);
        display: flex;
        flex-basis: calc(100% - 100px);
        flex-direction: row;

        &.actionsMenuColumn {
          width: auto !important;
          flex-direction: row-reverse;
        }
      }
    }
  }
}

.tableWrapper {
  @include breakpoints.breakpoint-up(lg) {
    padding: 0 var(--container-px);
    background-color: var(--color-white);
  }

  &.flush {
    padding: 0;
  }
}

.responsiveTableContainer {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.filterRow {
  align-items: center;
  background: var(--color-gray-100);
  @include breakpoints.breakpoint-down(lg) {
    display: none;
  }
}

.tableCardHeader {
  position: sticky;
  z-index: 2;
  top: var(--bar-height);
  left: 0;
  right: 0;
  background-color: var(--color-white);

  &.timeline {
    top: 0;
  }
}

.tableThemeMedium {
  .tableCardHeader {
    background-color: var(--color-gray-100);
  }
}

.documentsTableCardContainer {
  margin-top: var(--bar-height);
}
