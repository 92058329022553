.form {
  position: relative;
}

.icon {
  position: absolute;
  z-index: 1;
  left: 0.375rem;
  top: 0.375rem;
}

.input {
  padding-left: calc(var(--dimensions) + var(--button-py));
}
