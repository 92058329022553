.control {
  display: flex;
  border: 1px solid var(--border-color);
  border-radius: var(--radius-small);
  padding: var(--spacing-5);
  margin-bottom: var(--spacing-5);
}

.controlLabel {
  max-width: 70%;
  padding-right: var(--spacing-4);
}

.controlSetting {
  text-align: right;
  padding-left: var(--spacing-4);
}

.controlInfo {
  font-size: var(--font-size-sm);
  color: var(--color-gray-600);
  line-height: 1.3125;
}
