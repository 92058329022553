@use "../../scss/generic/Variables.module.scss";
@use "../../scss/utils/Breakpoints.module.scss" as breakpoints;

/* Custom properties */
:root {
  --tooltip-margin: 1.875rem;
  --tooltip-arrow-size: 8px;
  --tooltip-padding: 0.375rem;
  --popover-arrow-size: calc(var(--tooltip-arrow-size) * 2);
  --popover-padding: var(--spacing-5) var(--spacing-6);
}

.className {
  will-change: display;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: var(--z-index-tooltip);
  overflow: auto;
}

.popover {
  position: relative;
  top: 0;
  left: 0;
  width: 12.5rem;
  max-width: 500px;
  z-index: var(--z-index-tooltip);
  word-wrap: break-word;
  padding: var(--popover-padding);
  border-radius: var(--popover-border-radius);
  box-shadow: 0 2px 12px rgb(0 0 0 / 20%);
  background-color: var(--color-white);
  will-change: transform;
}

// Fix the alignment of tooltip arrow
.tooltipContainer {
  display: inline-block;
}

.tooltipArrow {
  fill: var(--tooltip-bg);
  width: var(--tooltip-arrow-size);
  height: var(--tooltip-arrow-size);
}

.tooltip {
  pointer-events: none; // to remove flickering of tooltip
  color: white;
  background: var(--tooltip-bg);
  box-shadow: none;
  border-radius: var(--tooltip-border-radius);
  padding: var(--tooltip-padding);
  font-size: var(--font-size-sm);
  line-height: 1.2;
  white-space: normal;

  // center text in tooltip
  * {
    text-align: center !important;
  }
}

// prevent tooltips inside popovers from exeding the popover's width
.popover .tooltip {
  max-width: 75%;
}

// Allow text with overflow ellipsis to show full text on hover
.ellipsesTooltip {
  display: revert;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Allow truncated text inside flexbox elements https://css-tricks.com/flexbox-truncated-text/
.tooltipPropContainer {
  min-width: 0;
}

.closeBtn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  @include breakpoints.breakpoint-up("sm") {
    display: none !important;
  }
}
