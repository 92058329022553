@use "./Variables.module.scss";

.b {
  border: 1px solid var(--border-color);
}

.by {
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}

.bx {
  border-right: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
}

.bt {
  border-top: 1px solid var(--border-color);
}

.br {
  border-right: 1px solid var(--border-color);
}

.bb {
  border-bottom: 1px solid var(--border-color);
}

.bl {
  border-left: 1px solid var(--border-color);
}
