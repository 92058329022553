@use "sass:color";
@use "sass:list";
@use "sass:map";
@use "sass:math";
@use "sass:meta";
@use "sass:string";
@use "../generic/Variables.module.scss";
@use "SassVariables.module" as *;
@use "Functions.module" as functions;
@use "Breakpoints.module" as *;

// Grid system
// Generate semantic grid columns with mixins.

@mixin make-row($gutter: $grid-gutter-width) {
  --#{$variable-prefix}gutter-x: #{$gutter};
  --#{$variable-prefix}gutter-y: 0;

  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--#{$variable-prefix}gutter-y)); // stylelint-disable-line function-disallowed-list
  margin-right: calc(-0.5 * var(--#{$variable-prefix}gutter-x)); // stylelint-disable-line function-disallowed-list
  margin-left: calc(-0.5 * var(--#{$variable-prefix}gutter-x)); // stylelint-disable-line function-disallowed-list
}

@mixin make-col-ready($gutter: $grid-gutter-width) {
  // Add box sizing if only the grid is loaded
  box-sizing: if(meta.variable-exists(include-column-box-sizing) and $include-column-box-sizing, border-box, null);
  flex-shrink: 0;
  width: 100%;
  max-width: 100%; // Prevent `.col-auto`, `.col` (& responsive variants) from breaking out the grid
  padding-right: calc(var(--#{$variable-prefix}gutter-x) * 0.5); // stylelint-disable-line function-disallowed-list
  padding-left: calc(var(--#{$variable-prefix}gutter-x) * 0.5); // stylelint-disable-line function-disallowed-list
  margin-top: var(--#{$variable-prefix}gutter-y);
}

@mixin make-col($size: false, $columns: $grid-columns) {
  @if $size {
    flex: 0 0 auto;
    width: math.percentage(math.div($size, $columns));
  } @else {
    flex: 1 1 0;
    max-width: 100%;
  }
}

@mixin make-col-auto() {
  flex: 0 0 auto;
  width: auto;
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: math.div($size, $columns);

  margin-left: if($num == 0, 0, math.percentage($num));
}

// Row columns

// Specify on a parent element(e.g., .row) to force immediate children into NN
// numberof columns. Supports wrapping to new lines, but does not do a Masonry
// style grid.
@mixin row-cols($count) {
  > * {
    flex: 0 0 auto;
    width: divide(100%, $count);
  }
}

// Grid generation

// Used to generate the correct number of grid classes given
// any value of `$grid-columns`.

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
  @each $breakpoint in map.keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @include breakpoint-up($breakpoint, $breakpoints) {
      // Provide basic `.col-{bp}` classes for equal-width flexbox columns
      .col#{$infix} {
        flex: 1 0 0%; // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      }

      .row-cols#{$infix}-auto > * {
        /* stylelint-disable-next-line scss/at-mixin-argumentless-call-parentheses */
        @include make-col-auto();
      }

      @if $grid-row-columns > 0 {
        @for $i from 1 through $grid-row-columns {
          .row-cols#{$infix}-#{$i} {
            @include row-cols($i);
          }
        }
      }

      .col#{$infix}-auto {
        /* stylelint-disable-next-line scss/at-mixin-argumentless-call-parentheses */
        @include make-col-auto();
      }

      @if $columns > 0 {
        @for $i from 1 through $columns {
          .col#{$infix}-#{$i} {
            @include make-col($i, $columns);
          }
        }

        // `$columns - 1` because offsetting by the width of an entire row isn't possible
        @for $i from 0 through ($columns - 1) {
          @if not($infix == "" and $i == 0) {
            // Avoid emitting useless .offset-0
            .offset#{$infix}-#{$i} {
              @include make-col-offset($i, $columns);
            }
          }
        }
      }

      // Gutters

      // Make use of `.g-*`, `.gx-*` or `.gy-*` utilities to change spacing between the columns.
      @each $key, $value in $gutters {
        .g#{$infix}-#{$key},
        .gx#{$infix}-#{$key} {
          --#{$variable-prefix}gutter-x: #{$value};
        }

        .g#{$infix}-#{$key},
        .gy#{$infix}-#{$key} {
          --#{$variable-prefix}gutter-y: #{$value};
        }
      }
    }
  }
}

@mixin make-cssgrid($columns: $grid-columns, $breakpoints: $grid-breakpoints) {
  @each $breakpoint in map.get($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @include breakpoint-up($breakpoint, $breakpoints) {
      @if $columns > 0 {
        @for $i from 1 through $columns {
          .g-col#{$infix}-#{$i} {
            grid-column: auto / span $i;
          }
        }

        // Start with `1` because `0` is an invalid value.
        // Ends with `$columns - 1` because offsetting by the width of an entire row isn't possible.
        @for $i from 1 through ($columns - 1) {
          .g-start#{$infix}-#{$i} {
            grid-column-start: $i;
          }
        }
      }
    }
  }
}

// Utilities Generator

@mixin generate-utility($utility, $infix) {
  $values: map.get($utility, values);

  // If the values are a list or string, convert it into a map
  @if meta.type-of($values) == "string" or meta.type-of(list.nth($values, 1)) != "list" {
    $values: list.zip($values, $values);
  }

  @each $key, $value in $values {
    $properties: map.get($utility, property);

    // Multiple properties are possible, for example with vertical or horizontal margins or paddings
    @if meta.type-of($properties) == "string" {
      $properties: list.append((), $properties);
    }

    // Use custom class if present
    $property-class: if(map.has-key($utility, class), map.get($utility, class), list.nth($properties, 1));
    $property-class: if($property-class == null, "", $property-class);

    // State params to generate pseudo-classes
    $state: if(map.has-key($utility, state), map.get($utility, state), ());
    $infix: if($property-class == "" and string.slice($infix, 1, 1) == "-", string.slice($infix, 2), $infix);

    // Don't prefix if value key is null (eg. with shadow class)
    $property-class-modifier: if($key, if($property-class == "" and $infix == "", "", "-") + $key, "");
    $is-css-var: map.get($utility, css-var);
    $is-local-vars: map.get($utility, local-vars);
    $is-rtl: map.get($utility, rtl);

    /* stylelint-disable-next-line scss/at-if-no-null */
    @if $value != null {
      @if $is-rtl == false {
        /* rtl:begin:remove */
      }

      /* stylelint-disable annotation-no-unknown */
      @if $is-css-var {
        .#{$property-class + $infix + $property-class-modifier} {
          --#{$variable-prefix}#{$property-class}: #{$value};
        }

        @each $pseudo in $state {
          .#{$property-class + $infix + $property-class-modifier}-#{$pseudo}:#{$pseudo} {
            --#{$variable-prefix}#{$property-class}: #{$value};
          }
        }
      } @else {
        .#{$property-class + $infix + $property-class-modifier} {
          @each $property in $properties {
            @if $is-local-vars {
              @each $local-var, $value in $is-local-vars {
                --#{$variable-prefix}#{$local-var}: #{$value};
              }
            }

            #{$property}: $value if($enable-important-utilities, !important, null);
          }
        }

        @each $pseudo in $state {
          .#{$property-class + $infix + $property-class-modifier}-#{$pseudo}:#{$pseudo} {
            @each $property in $properties {
              #{$property}: $value if($enable-important-utilities, !important, null);
            }
          }
        }
      }
      /* stylelint-enable annotation-no-unknown */

      @if $is-rtl == false {
        /* rtl:end:remove */
      }
    }
  }
}

@mixin make-container($gutter: $container-padding-x) {
  width: 100%;
  padding-right: (#{$gutter});
  padding-left: (#{$gutter});
  margin-right: auto;
  margin-left: auto;
}
