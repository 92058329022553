@use "../../../../scss/utils/Breakpoints.module.scss" as breakpoints;

.className {
  position: relative;
  transition: var(--transition-base);

  @include breakpoints.breakpoint-up(lg) {
    padding: 0;
  }

  &:hover {
    transition: var(--transition-base);
    background: var(--color-hover);
    color: var(--color-gray-700);

    .text {
      color: var(--color-gray-700);
    }
  }
}

.inaccessible {
  opacity: 0.5;
}

.denied {
  opacity: 0.5;
  cursor: not-allowed;
}

.hideLg {
  display: block;

  @include breakpoints.breakpoint-up(lg) {
    display: none;
  }
}

.link {
  display: block;
  align-items: center;
  height: auto;
  text-decoration: none;
  color: var(--color-gray-700);
  font-weight: var(--font-weight-medium);
  padding: 1rem 2.5rem;

  @include breakpoints.breakpoint-up(lg) {
    display: flex;
    padding: 0 1.25rem;
    height: var(--bar-height);
  }

  @include breakpoints.breakpoint-up(xl) {
    padding: 0 2.5rem;
  }
}

.span {
  padding: 1rem;
  display: block;
}

.innerWrapper {
  display: flex;
  align-items: center;

  @include breakpoints.breakpoint-up(lg) {
    text-align: center;
    display: block;
    position: relative;
  }
}

.icon {
  height: 2rem;
  width: 2rem;
  color: var(--color-gray-700);
}

.active {
  background-color: var(--color-tab-active);
  position: relative;
  border-right: 3px solid var(--color-primary);
  transition: transform 0.25s ease-out;

  @include breakpoints.breakpoint-up(lg) {
    border-right: none;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 3px;
      background-color: var(--color-primary);
    }
  }

  &.link {
    color: var(--color-dark);
  }

  .icon {
    color: var(--color-dark) !important;
  }

  &::after {
    background-color: var(--color-primary);
    transform: scaleX(1);
  }

  &:hover {
    &::after {
      background-color: var(--color-primary);
      transform: scaleX(1);
      transform-origin: 50% 0;
    }

    .text,
    .link {
      color: var(--color-dark) !important;
    }

    .icon {
      color: var(--color-dark) !important;
    }
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.text {
  font-size: var(--font-size-sm);
  padding-left: 1.25rem;

  @include breakpoints.breakpoint-up(lg) {
    padding-left: 0;
    text-align: center;
    font-size: var(--font-size-xs);
    letter-spacing: 0.025em;
  }
}

.badge {
  right: -0.25rem;
}
