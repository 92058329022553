.expiresOnWrapper {
  gap: var(--spacing-5);
}

.messageArea {
  border: 1px solid var(--input-border-color);
  padding: calc(var(--button-py) * 1.5) calc(var(--button-px) - var(--border-width));
  border-radius: var(--radius-small);

  &:has(textarea:focus) {
    border-color: var(--input-border-color-focus);
  }
}

.messageTextarea {
  border: none;
  resize: none;
  padding: 0;

  &:focus {
    outline: none;
  }
}

.staticMessage {
  color: var(--color-gray-700);
  user-select: none;

  &:hover {
    cursor: initial;
    color: var(--color-gray-500);
  }
}

.dummyLink {
  text-decoration: underline;
}
