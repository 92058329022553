@use "../../../scss/generic/Variables.module.scss";

.className {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
}

.small {
  width: var(--icon-size-sm);
  height: var(--icon-size-sm);
  min-width: var(--icon-size-sm);
  min-height: var(--icon-size-sm);
}

.medium {
  width: var(--icon-size-base);
  min-width: var(--icon-size-base); // prevent browser's auto min-width setting
  height: var(--icon-size-base);
  min-height: var(--icon-size-base);
}

.large {
  width: 2.5rem;
  height: 2.5rem;
}

.primary {
  color: var(--color-primary);
}

.secondary {
  color: var(--color-gray-600);
}

.dark {
  color: var(--color-gray-900);
}

.light {
  color: white;
}

.warning {
  color: var(--color-warning);
}

.info {
  color: var(--color-info);
}

.error {
  color: var(--color-error) !important;
}

.success {
  color: var(--color-success);
}
