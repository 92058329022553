@use "../../scss/generic/Variables.module.scss";

// Alignment
.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.justify {
  text-align: justify;
}

// Weight
.lighter {
  font-weight: var(--font-weight-light);
}

.medium {
  font-weight: var(--font-weight-medium);
}

.bold {
  font-weight: var(--font-weight-semi-bold);
}

.bolder {
  font-weight: var(--font-weight-bold);
}

// Style
.normal {
  font-style: normal;
}

.italic {
  font-style: italic;
}

.oblique {
  font-style: oblique;
}

// Casing
.lower {
  text-transform: lowercase;
}

.upper {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

// Color
.primary {
  color: var(--color-primary);
}

.secondary {
  color: var(--color-gray-900);
}

.light {
  color: var(--color-gray-600);
}

.dark {
  color: var(--color-gray-900);
}

// Variant
small,
.small {
  font-size: var(--font-size-xs);
  font-weight: normal;
}

.preHeading {
  font-size: var(--font-size-sm);
  font-weight: var(--body-font-weight);
  margin-bottom: var(--heading-margin-bottom);
}

// Headings
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: var(--heading-font-weight);
}

h1,
.h1 {
  font-size: var(--h1-font-size);
}

h2,
.h2 {
  font-size: var(--h2-font-size);
}

h3,
.h3 {
  font-size: var(--h3-font-size);
}

h4,
.h4 {
  font-size: var(--h4-font-size);
}

h5,
.h5 {
  font-size: var(--h5-font-size);
  margin-bottom: 1rem;
}

h6,
.h6 {
  font-size: var(--h6-font-size);
  margin-bottom: 1rem;
}

// Text wrapping and overflow
.wrap {
  white-space: normal;
}

.noWrap {
  white-space: nowrap;
}

// Remove the margin-bottom from the last <p> element
p {
  &:last-of-type {
    margin-bottom: 0;
  }
}

// Wrapping and overflow
.break-word {
  word-break: break-word;
}

.break-all {
  word-break: break-all;
}
