@use "../../scss/generic/Variables.module.scss";
@use "../../scss/generic/Animations.module.scss";

.className {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spinner {
  // set up the default spinner

  --weight: 4px;

  position: relative;
  border-radius: 50%;
  z-index: var(--z-index-spinner);
  width: calc(var(--weight) * 6);
  height: calc(var(--weight) * 6);
  font-size: calc(var(--weight) * 4); // for icon
  color: var(--color-primary);

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    margin: calc(var(--weight) * -1);
    border-radius: inherit;
    border-top: var(--weight) solid;
    border-right: var(--weight) solid transparent;
    border-left: var(--weight) solid transparent;
    animation: spin 1s linear infinite;
    width: inherit;
    height: inherit;
    font-size: inherit;
  }

  // Sizes

  &.small {
    --weight: 2px;

    width: 20px;
    height: 20px;
    border: var(--weight) solid;
  }

  &.medium {
    width: 50px;
    height: 50px;
    border: var(--weight) solid;
  }

  &.large {
    --weight: 6px;

    width: 100px;
    height: 100px;
    border: var(--weight) solid;
  }

  // Icons

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  // Colors

  &.primary {
    border-color: rgb(0 0 0 / 15%);

    &::after {
      border-top-color: var(--color-primary);
    }

    .icon {
      color: inherit;
    }
  }

  &.light {
    border-color: rgb(0 0 0 / 15%);

    &::after {
      border-top-color: var(--color-gray-100);
    }
  }
}

.typography {
  color: var(--color-primary);
  font-size: 0.75rem;
}
