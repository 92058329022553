@use "../../../../scss/generic/Variables.module.scss";
@use "../../../../scss/utils/Breakpoints.module.scss" as breakpoints;

.className {
  padding: var(--spacing-4) var(--container-px);
  background: var(--color-gray-100);

  @include breakpoints.breakpoint-down(md) {
    display: none;
  }
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}
