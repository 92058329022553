@use "sass:meta"; // Necessary to prevent interpolation from removing quotes around strings

@use "Fonts.module.scss" as fonts;

:root {
  // Spacing
  --spacing-base: 1rem;

  // 1px
  --spacing-1: calc(var(--spacing-base) * 0.0625);

  // 2px
  --spacing-2: calc(var(--spacing-base) * 0.125);

  // 4px
  --spacing-3: calc(var(--spacing-base) * 0.25);

  // 8px
  --spacing-4: calc(var(--spacing-base) * 0.5);

  // 16px
  --spacing-5: calc(var(--spacing-base) * 1);

  // 24
  --spacing-6: calc(var(--spacing-base) * 1.5);

  // 32
  --spacing-7: calc(var(--spacing-base) * 2);

  // 48
  --spacing-8: calc(var(--spacing-base) * 3);

  // Brand Colors
  --color-teal: #00959a;
  --color-navy: #0d2941;
  --color-charcoal: #5c5e5d;
  --color-red: #e34946;
  --color-green: #06c778;
  --color-cyan: #33b3de;
  --color-orange: #fd9230;
  --color-yellow: #ffbc53;

  // Neutral Colors
  --color-black: #000;
  --color-gray-900: #424242;
  --color-gray-800: #616161;
  --color-gray-700: #737475;
  --color-gray-600: #959ca0;
  --color-gray-500: #a3abb5;
  --color-gray-400: #cdcecf;
  --color-gray-300: #e0e0e0;
  --color-gray-200: #ebeced;
  --color-gray-100: #f5f5f5;
  --color-white: #fff;
  --color-hover: #edf9f9;

  // Theme colors
  --color-primary: var(--color-teal);
  --color-tab-active: rgb(6 203 203 / 12%);
  --color-tab-hover: rgb(6 203 203 / 50%);
  --color-primary-light: #06cbcb;
  --color-primary-medium: #05a6a4;
  --color-primary-dark: #007c87;
  --color-dark: var(--color-navy);
  --color-medium: var(--color-charcoal);
  --color-navy-medium: #455b6d;
  --color-light: var(--color-gray-200);
  --color-secondary: var(--color-gray-200);
  --color-info: var(--color-cyan);
  --color-info-light: #e9fafe;
  --color-success: var(--color-green);
  --color-success-light: #f7fcf7;
  --color-warning: var(--color-yellow);
  --color-warning-light: #fff9ed;
  --color-error: var(--color-red);
  --color-error-light: #fcf0f0;
  --color-error-dark: #db7877;
  --color-error-medium: #fbd9d9;
  --color-link: var(--color-primary);
  --color-link-hover: var(--color-primary-dark);
  --color-accent: #507883;
  --color-accent-light: #88a9b0;

  // Radii
  --radius-small: 0.25rem;
  --radius-medium: 0.4375rem;
  --radius-large: 0.625rem;

  // Typography
  --font-size-base: 1rem;
  --font-size-xxs: calc(var(--font-size-base) * 0.625);
  --font-size-xs: calc(var(--font-size-base) * 0.75);
  --font-size-sm: calc(var(--font-size-base) * 0.875);
  --font-size-md: calc(var(--font-size-base) * 0.9375);
  --font-size-lg: calc(var(--font-size-base) * 1.25);
  --font-monospace: sfmono-regular, menlo, monaco, consolas, "Liberation Mono", "Courier New", monospace;
  --h1-font-size: calc(var(--font-size-base) * 2.25);
  --h2-font-size: calc(var(--font-size-base) * 1.875);
  --h3-font-size: calc(var(--font-size-base) * 1.5);
  --h4-font-size: calc(var(--font-size-base) * 1.25);
  --h5-font-size: calc(var(--font-size-base) * 1.125);
  --h6-font-size: var(--font-size-base);
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --heading-font-weight: var(--font-weight-medium);
  --heading-margin-bottom: calc(var(--spacing-base) / 2);
  --heading-letter-spacing: -0.018em;

  // Body

  --body-font-family: #{meta.inspect(fonts.$fontstack)};
  --body-font-size: var(--font-size-base);
  --body-font-weight: 400;
  --body-line-height: 1.5;

  // Omit the unit for scaling flexibility
  --body-color: #111;
  --body-bg: #fff;
  --body-text-align: left;

  // Borders
  --border-width: 1px;
  --border-color: var(--color-gray-200);

  // Icons
  --icon-size-base: 1.5em;
  --icon-size-sm: 0.875em;

  // Transitions
  --transition-base: all 0.15s ease-in-out;

  // Buttons
  --button-py: 0.375rem;
  --button-px: 0.75rem;
  --button-py-sm: var(--spacing-3);
  --button-px-sm: 0.5rem;
  --button-py-lg: 0.75rem;
  --button-px-lg: 1.3rem;
  --button-spacing: calc(var(--grid-column-gutter) * 0.5);

  // Inputs
  --input-border-color: var(--color-gray-400);
  --input-disabled-border-color: var(--color-gray-100);
  --input-border-color-focus: var(--color-primary-light);

  // Disabled states
  --disabled-opacity: 0.35;

  // Z-index
  --z-index-spinner: 900;
  --z-index-select: 1000;
  --z-index-drawer: 1040;
  --z-index-support: 1041;
  --z-index-navbar: 1050;
  --z-index-backdrop-spinner: 1060;
  --z-index-overlay: 1090;
  --z-index-modal: 1100;
  --z-index-tooltip: 1200;

  // Grid
  --grid-column-gutter: calc(var(--spacing-base) * 1.5);
  --grid-column-gutter-md: calc(var(--grid-column-gutter) / 2);
  --grid-column-gutter-sm: calc(var(--grid-column-gutter) / 4);
  --container-px: 2.5rem;

  // Navbars / Banners / Panels / Toolbars
  --bar-height: 4rem;

  // Breadcrumbs
  --breadcrumb-height: var(--bar-height);
  --breadcrumb-divider: "/";
  --breadcrumb-spacing-x: var(--spacing-4);
  --breadcrumb-divider-color: var(--color-gray-400);
  --breadcrumb-active-color: var(--color-gray-600);

  // Modals
  --modal-sm: 360px;
  --modal-md: 500px;
  --modal-lg: 800px;
  --modal-xl: 1140px;
  --modal-xxl: 1400px;
  --modal-fullscreen: 100%;

  // Popover
  --popover-border-radius: var(--radius-medium);

  // Tooltip
  --tooltip-bg: var(--color-gray-900);
  --tooltip-border-radius: var(--radius-small);
}
