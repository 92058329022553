@use "../../../../scss/generic/Variables.module.scss";

@use "../../../../scss/utils" as *;

.overflowFix {
  /* https://css-tricks.com/flexbox-truncated-text/ */
  min-width: 0;
}

.content {
  background: white;
  min-width: 300px;
}

.dateRow {
  height: 100%;
}

.date {
  width: 3.75rem;
  height: 100%;
}

.currentState {
  border-left-width: 0.25rem;
  border-left-style: solid;

  &.pending {
    border-left-color: var(--color-info);
    background: var(--color-info-light);
  }

  &.expired {
    border-left-color: var(--color-black);
    background: var(--color-gray-100);
  }

  &.high {
    border-left-color: var(--color-error);
    background: var(--color-error-light);
  }

  &.medium {
    border-left-color: var(--color-warning);
    background: var(--color-warning-light);
  }

  &.low {
    border-left-color: var(--color-success);
    background: var(--color-success-light);
  }

  &.task {
    border-left-color: var(--color-accent);
    background: #f5f7f8;
  }
}

.dateWeekDay {
  display: block;
  font-size: var(--font-size-xxs);
  line-height: 1;
  margin-bottom: 0.25rem;
  letter-spacing: 0.025em;
}

.dateDay {
  display: block;
  line-height: 1;
  margin-bottom: 0.15rem;
  letter-spacing: -0.075em;
}

.dateMonth {
  display: block;
  font-size: var(--font-size-xs);
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 0.25rem;
}

.dateYear {
  display: block;
  font-size: var(--font-size-xxs);
  color: var(--color-gray-600);
  line-height: 1;
}

.documentDescription {
  font-size: var(--font-size-sm);
  color: var(--color-gray-600);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
}

.listAction {
  display: flex;
  justify-content: center;
}

.notificationTitle {
  line-height: 1.2rem;
  max-height: 2.4rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.strikethrough {
  text-decoration: line-through;
}
