@use "../../../../../../scss/utils/Breakpoints.module.scss" as breakpoints;

.versionNotes {
  width: 100%;
  max-width: 600px;

  @include breakpoints.breakpoint-up("md") {
    width: 600px;
  }
}
