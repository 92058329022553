.className {
  background-color: var(--color-gray-200);
  border-radius: 0.25rem;
  width: 15rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.text {
  font-weight: bold;
  margin-left: var(--spacing-5);
}

.button {
  width: 4.876rem;
}

.success {
  color: var(--color-success);
}
