@use "../../scss/generic/Variables.module.scss";

.className {
  font-size: 1rem;
  padding: calc(var(--button-py) - var(--border-width)) calc(var(--button-px) - var(--border-width));
  border: 1px solid var(--input-border-color);
  border-radius: var(--radius-small);
}

.className:focus,
.className:focus-visible,
.className:focus-within {
  border-color: var(--input-border-color-focus);
  outline: none;
}

.success {
  border-color: var(--color-success);
}

.recaptcha {
  input,
  label {
    height: 0;
  }
}

.errorTextWrapper {
  font-size: var(--font-size-sm);
  min-height: 0; // Change to 1rem to enable fixed space error text
  line-height: normal;
  margin-top: var(--spacing-3);
}

.error {
  font-size: var(--font-size-sm);
  color: var(--color-error);
}

.errorField {
  border-color: var(--color-error);
}

.switchGrid {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  font-size: var(--font-size-sm);
}
