.iconWrapper {
  text-align: center;
}

.icon {
  width: 3.5rem;
  height: 3.5rem;
}

.buttonWrapper {
  text-align: center;
}

.button {
  padding-right: var(--spacing-8);
  padding-left: var(--spacing-8);
}
