@use "../../../../scss/utils/Breakpoints.module.scss" as breakpoints;

.tableWrapper {
  background: var(--color-white);
  border-radius: 0 0 var(--radius-medium) var(--radius-medium);
  height: 100%;
  min-height: 280px;
  padding: var(--spacing-4) var(--spacing-5);
}

.group {
  @include breakpoints.breakpoint-down(md) {
    line-height: 1.25;
    margin-bottom: 0.25rem;
  }
}
