@use "../../../../scss/utils/Breakpoints.module.scss" as breakpoints;

.className {
  background: var(--color-accent);
  color: var(--color-white);
  border-radius: var(--radius-medium) var(--radius-medium) 0 0;
}

.urn {
  font-family: var(--font-monospace);
  font-size: var(--font-size-md);
}

.matterType {
  color: var(--color-white);
  margin-bottom: 0;
  font-weight: var(--font-weight-normal);
}

.title {
  color: var(--color-white);
  margin-bottom: 0;
  font-weight: var(--font-weight-medium);
}

.moreDetailsBtn {
  white-space: nowrap;
  color: var(--color-primary-light) !important;
  font-size: var(--font-size-sm);
}

.milestoneWrapper {
  margin-left: -0.3125rem;
}

.milestoneDisplayName {
  margin-left: var(--spacing-3);
  font-size: var(--font-size-sm);
}

.tagsRow {
  width: 100%;

  @include breakpoints.breakpoint-up(md) {
    width: calc(100% - 85px);
  }
}

.tagsCol {
  overflow: hidden;
}

.secondTagsCol {
  display: none;

  @include breakpoints.breakpoint-up(md) {
    display: block;
  }
}

.label {
  color: rgb(255 255 255 / 60%);
  white-space: nowrap;
  margin-right: var(--spacing-5);
  font-size: var(--font-size-sm);
}

.valuesWrapper {
  overflow: hidden;
}

.value {
  font-size: var(--font-size-sm);
}
