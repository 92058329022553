@use "../../scss/generic/Variables.module.scss";

.className {
  background: var(--color-gray-100);
  width: 100%;
}

.small {
  height: 0.625rem;
  padding: 0.0625rem;
}

.medium {
  height: 1.25rem;
  padding: 0.125rem;
}

.large {
  height: 1.875rem;
  padding: 0.1875rem;
}

.bar {
  background: var(--color-primary);
  height: 100%;
}
