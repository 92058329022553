@use "../../../../scss/utils/Breakpoints.module.scss" as breakpoint;

.addUserBtn {
  flex: 1 0 auto;
}

.userDisabled {
  color: var(--color-gray-500);
  margin-right: 10px;
}
