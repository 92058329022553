.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  height: var(--breadcrumb-height);
  padding: 0;
  list-style: none;
  font-size: var(--font-size-sm);
  align-items: center;
  margin-bottom: 0;

  li {
    + li {
      padding-left: var(--breadcrumb-spacing-x);

      &::before {
        content: var(--breadcrumb-divider);
        padding-right: var(--breadcrumb-spacing-x);
        color: var(--breadcrumb-divider-color);
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &.active {
      color: var(--breadcrumb-active-color);
    }

    a {
      color: var(--color-link);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
