@use "../../../scss/generic/Variables.module.scss";
@use "../../../scss/generic/PageContent.module.scss" as pagecontent;
@use "../../../scss/utils/Breakpoints.module.scss" as breakpoint;

.pageContent {
  @include pagecontent.content;

  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;

  @include breakpoint.breakpoint-down(md) {
    padding-right: 0;
    padding-left: 0;
  }
}

.notificationsPageWrapper {
  flex-wrap: wrap;
}

.kanbanBoard {
  align-items: start;
  overflow-x: auto;
  height: calc(100vh - 4.5rem);
  flex-wrap: nowrap;
}

.kanbanColumn {
  display: flex;
  flex: 0 0 33%;
  min-width: 360px;
  align-self: stretch;
  flex-direction: column;
  border-left: 1px solid var(--color-gray-400);

  &:first-of-type {
    &.columnBorder {
      border-left: none;
    }
  }
}

.scrollingColumnsContainer {
  position: relative;

  @include breakpoint.breakpoint-up(lg) {
    height: calc(100vh - var(--bar-height));
    overflow-y: hidden;
  }
}

.leftCol {
  height: 100%;
  overflow-y: auto;
  padding: var(--spacing-8) var(--spacing-5);

  @include breakpoint.breakpoint-up(lg) {
    padding-right: calc(var(--container-px) * 0.75);
    padding-left: var(--container-px);
  }
}

.rightCol {
  height: 100%;
  overflow-y: auto;
  padding: var(--spacing-8) var(--spacing-5);
  @include breakpoint.breakpoint-up(lg) {
    padding-right: var(--container-px);
    padding-left: calc(var(--container-px) * 0.75);
  }

  @include breakpoint.breakpoint-up(lg) {
    border-left: 1px solid var(--color-gray-400);
  }
}

.notificationsTabs {
  position: fixed;
  z-index: 3;
  height: var(--bar-height);
  width: 100%;
  padding-right: var(--container-px) !important;
  padding-left: var(--container-px) !important;
  justify-content: center;

  @include breakpoint.breakpoint-down(md) {
    padding-right: var(--spacing-5) !important;
    padding-left: var(--spacing-5) !important;
    justify-content: flex-start;
  }

  * {
    justify-content: flex-start;
  }
}

.emptyStateWrapper {
  color: var(--color-gray-600);
  text-align: center;
}

.emptyStateIcon {
  color: var(--color-gray-400);
  margin-bottom: var(--spacing-3);
}
