@use "./scss/generic/Variables.module.scss";
@use "./scss/generic/Reset.module.scss";

// Third party overrides
@use "./scss/third-party-overrides/ReactDatePicker.scss";
@use "./scss/third-party-overrides/ReactPhoneNumberInput.scss";
@use "./scss/third-party-overrides/SelectAutocomplete.scss";
@use "./scss/third-party-overrides/ReactToastify.scss";

.App {
  min-height: 100vh;
  background-color: #282c34;
}
