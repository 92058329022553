// .overlayBase = backdrop, non-scrollable
// .base = positioning shell for the modal
// .modalBox = the styled "container" containing the modalHeader (optional), modalBody (required), modalFooter (optional)
// .modalBody = scrollable section with the contents of the modal
// .scrollable = makes modalBody scrollable

@use "../../scss/generic/Variables.module.scss";
@use "../../scss/utils" as *;
@use "../../scss/utils/Breakpoints.module.scss" as breakpoints;

:root {
  --modal-margin: var(--spacing-6);
  --modal-px: var(--spacing-6);
  --modal-py: var(--spacing-6);
}

.base {
  position: relative;
  align-self: center;
  overflow-y: visible;
  outline: 0;
  opacity: 0;
  transform: translateY(20px);
  bottom: auto;
  margin: auto;
  max-height: calc(100vh - 1.5rem);
  z-index: var(--z-index-modal);
  display: flex;
  flex-direction: column;
  pointer-events: none;
  background-clip: padding-box;
  width: 100%;
  max-width: var(--modal-md);
}

.fullscreen {
  max-width: var(--modal-fullscreen) !important;
  width: 100vw;
  height: 100%;
  max-height: none;
  margin: 0;
  position: relative;
  transform: none;
  flex-direction: row;
}

.afterOpen {
  opacity: 1;
  transform: translateY(0);
}

.beforeClose {
  opacity: 0;
  transform: translateY(1.25rem);
}

.overlayBase {
  position: fixed;
  display: flex;
  justify-content: center;
  background-color: rgb(0 0 0 / 60%);
  transition: opacity 0.2s;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: var(--z-index-modal);
  width: 100%;
  height: 100%;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 2%;
  padding-right: 2%;
  scrollbar-gutter: stable both-edges;
}

.overlayAfterOpen {
  opacity: 1;
}

.overlayBeforeClose {
  opacity: 0;
}

.modalBox {
  max-height: 100%;
  overflow: inherit;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0;
  border-radius: var(--radius-medium);
  background-color: white;
  box-shadow:
    rgb(0 0 0 / 18%) 0 25.6px 57.6px 0,
    rgb(0 0 0 / 16%) 0 4.8px 14.4px 0;

  @include breakpoint-down(sm) {
    min-width: 280px;
  }
}

// Sizes
.sm {
  max-width: var(--modal-sm) !important;
}

.md {
  max-width: var(--modal-md) !important;
}

.lg {
  max-width: var(--modal-lg) !important;
}

.xl {
  max-width: var(--modal-xl) !important;
}

.xxl {
  max-width: var(--modal-xxl) !important;
  height: calc(100% - 2%) !important;
  max-height: none;
  margin: 0;
  position: relative;
  transform: none;

  .modalBox {
    height: 100%;
  }
}

@mixin header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  h2,
  h3,
  h4 {
    margin-bottom: 0 !important;
  }

  ol {
    height: auto;
  }
}

.modalHeaderWithShadow {
  @include header;

  z-index: 1;
  flex-shrink: 0;
  border-bottom: var(--border-width) solid var(--border-color);
  box-shadow: 0 3px 10px rgb(0 0 0 / 6%);
  padding: var(--modal-py) var(--modal-px);
}

.modalHeaderNoShadow {
  @include header;

  padding: var(--modal-py) var(--modal-px) calc(var(--modal-py) * 0.25) var(--modal-px);
}

.modalBody {
  position: relative;
  flex: 1 1 auto;
  padding: var(--modal-py) var(--modal-px);
  z-index: 2;
}

@mixin footer {
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: var(--modal-py) var(--modal-px);

  @include breakpoints.breakpoint-up(sm) {
    flex-direction: row;
  }

  button {
    width: 100%;
    margin-bottom: var(--spacing-4);

    &:last-of-type {
      margin-bottom: 0;
    }

    @include breakpoints.breakpoint-up(sm) {
      width: unset;
      margin-bottom: 0;
      margin-right: var(--button-spacing);

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.modalFooterWithShadow {
  @include footer;

  border-top: var(--border-width) solid var(--border-color);
  box-shadow: 0 -3px 10px rgb(0 0 0 / 6%);
}

.modalFooterNoShadow {
  @include footer;

  padding-top: calc(var(--modal-py) * 0.25);
}

.scrollable {
  .base {
    overflow-y: hidden;
  }

  .modalBody {
    overflow-y: auto;
  }

  .modalBox {
    max-height: 100%;
    overflow: hidden;
  }
}
