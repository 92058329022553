.header {
  position: relative;
}

.tasksEmpty {
  text-align: center;
}

.emptyIcon {
  width: 3.75rem;
  height: 3.75rem;
}

.emptyText {
  margin-top: var(--spacing-5);
}

.content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-sm);
}
