.lockedMsg {
  font-size: var(--font-size-sm);
  color: var(--color-orange);
}

.datetime {
  color: var(--color-gray-600);
}

.notificationSentIcon {
  margin-bottom: var(--spacing-3);
  margin-right: var(--spacing-3);
}

.success {
  color: var(--color-green);
}
