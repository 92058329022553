.dropzone {
  border: 1px dashed var(--input-border-color);
  border-radius: var(--radius-small);
  padding: var(--spacing-8) 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--color-gray-100);
  max-width: 28.125rem;
}

.dropzoneIcon {
  margin-bottom: var(--spacing-4);
}

.dropzoneText {
  display: flex;
}

.dropzoneBrowse {
  cursor: pointer;
  color: var(--color-primary);
}

.deleteIconWrapper {
  display: flex;
  background: var(--color-gray-100);
  padding: var(--spacing-3);
  cursor: pointer;
}

.fileNameSize {
  font-style: italic;
  font-size: var(--font-size-sm);
}

.fileName {
  word-break: break-all;
}

.error {
  color: var(--color-error);
}
