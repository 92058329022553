@use "../../../scss/generic/Variables.module.scss";
@use "../../../scss/utils/Breakpoints.module.scss" as breakpoint;
@use "../../../scss/generic/PageContent.module.scss" as pagecontent;

.interfaceWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: var(--color-gray-200);
}

.pageContent {
  @include pagecontent.content;

  // Change background-color for mobile table cards
  @include breakpoint.breakpoint-up(md) {
    background-color: var(--color-white);
  }
}

.className {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.group {
  line-height: 1.25;
  margin-bottom: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include breakpoint.breakpoint-up(md) {
    line-height: initial;
    margin-bottom: 0;
  }
}

.actionBar {
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  height: var(--bar-height);
  padding: var(--spacing-5) var(--container-px);

  @include breakpoint.breakpoint-down(md) {
    padding-top: 1rem !important;
    padding-left: 1rem;
  }
}

.tableContainer {
  position: relative;
  top: 0;
  min-height: 100vh;
}

.searchWrapper {
  flex: 0 1 auto;
}

.emptyStateWrapper {
  color: var(--color-gray-600);
  text-align: center;
}

.emptyStateIcon {
  color: var(--color-gray-400);
  width: 3.75rem;
  height: 3.75rem;
}
