@use "../../../scss/utils/Breakpoints.module.scss" as breakpoints;

.className {
  position: fixed;
  right: 0.5rem;
  bottom: 5rem;
  background: var(--color-white);
  border-radius: var(--radius-large);
  width: 22.188rem;
  display: flex;
  flex-direction: column;
  box-shadow: rgb(0 0 0 / 18%) 0 25.6px 57.6px 0, rgb(0 0 0 / 16%) 0 4.8px 14.4px 0;
  z-index: var(--z-index-modal);
  overflow-y: auto;
  max-height: calc(100vh - 5rem);

  @include breakpoints.breakpoint-up(sm) {
    right: 1.5rem;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-primary);
  border-top-left-radius: var(--radius-large);
  border-top-right-radius: var(--radius-large);
  padding: 0.5rem 1rem;
}

.headerText {
  color: var(--color-white);
}

.logoWraper {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  background: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
