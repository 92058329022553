@use "../../../scss/generic/Variables.module.scss";
@use "../../../scss/generic/Images.module.scss" as images;
@use "../../../scss/utils/Breakpoints.module.scss" as breakpoints;

:root {
  --navbar-logo-height: 34px;
  --navbar-py: var(--spacing-5);
  --navbar-px: var(--container-px);
  --arrow-offset: 20px;
}

.className {
  flex-wrap: nowrap;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--z-index-navbar);
  height: var(--bar-height);
  background-color: var(--color-white);
  box-shadow: 0 2px 6px 1px rgb(60 64 67 / 17%);
  padding: 0 var(--navbar-px);
}

.wrapper {
  background: white;
}

.logoWrapper {
  display: none;
  height: var(--navbar-logo-height);

  @include breakpoints.breakpoint-up(lg) {
    display: block;
  }
}

.hamburgerBtnWrapper {
  display: block;

  @include breakpoints.breakpoint-up(lg) {
    display: none;
  }
}

.hamburgerBtn {
  position: relative;
  z-index: 1030;
}

.menu {
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  flex-direction: row;
  list-style: none;
  height: var(--bar-height);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  overflow-y: hidden;

  @include breakpoints.breakpoint-down(lg) {
    display: block;
    position: fixed;
    height: 100vh;
    justify-content: left;
    align-items: left;
    top: 0;
    left: -100%;
    transition: var(--transition-base);
    padding: 4.375rem 0;
    z-index: 1020;
    background-color: var(--color-white);
  }
}

.menuOpen {
  left: 0;
}

.menuOverlay {
  background-color: rgb(0 0 0 / 60%);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1010;
  opacity: 0;
  transition: var(--transition-base);

  &.visible.in {
    opacity: 1;
  }

  @include breakpoints.breakpoint-up(lg) {
    display: none;
    visibility: hidden;
  }
}

.mobileMark {
  text-align: center;

  @include breakpoints.breakpoint-up(lg) {
    display: none;
  }
}

.markWrapper {
  padding: 0 calc(var(--arrow-offset) + 2px) 0 0;
  background-color: transparent;
  border: 1px solid transparent;
  transition: var(--transition-base);
  border-radius: var(--radius-small);

  &:hover {
    background-color: var(--color-gray-100);
  }
}

.mark {
  background: var(--color-gray-200);
  border-radius: 0.3125rem;
  height: 50px;
  width: 50px;
  position: relative;
  display: flex;

  &::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--color-gray-800);
    position: absolute;
    right: calc(var(--arrow-offset) * -1);
    top: 50%;
  }
}

.imageContainer {
  display: flex;
  align-self: center;
  justify-content: center;
  width: 100%;
}

.markImg {
  @include images.img-fluid;
}

.userIcon {
  color: var(--color-gray-800);
}

.helpCol,
.userCol {
  @include breakpoints.breakpoint-up(lg) {
    display: block;
  }
}

.rightWrapper {
  display: flex;
}

.profile {
  display: flex;
}

.icon {
  height: 2rem;
  width: 2rem;
  color: var(--color-gray-700);

  &:hover {
    color: var(--color-gray-400);
  }
}
