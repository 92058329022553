// Overrides reference: https://fkhadra.github.io/react-toastify/how-to-style/#override-existing-css-classes

:root {
  --toastify-color-dark: var(--color-navy-medium);
  --toastify-toast-min-height: 3rem;
}

.Toastify__toast {
  position: relative;
  padding: 0.5rem;
}

.Toastify__toast-body {
  padding: 0 1.5rem 0 1rem;
}

.Toastify__toast-icon {
  width: var(--icon-size-base);
  height: var(--icon-size-base);
  align-self: baseline;

  svg {
    color: var(--color-green);
    width: var(--icon-size-base);
    height: var(--icon-size-base);
  }
}

.Toastify__close-button {
  display: none;
}

.Toastify__toast-container {
  width: auto;
  min-width: 3rem;
}

.Toastify__toast-container--top-center {
  top: var(--spacing-7);
}

.Toastify__toast--error {
  .Toastify__toast-icon {
    margin-inline-end: 1rem;
  }

  .Toastify__toast-body {
    padding: 0.75rem 2rem 0.75rem 0.75rem;
  }

  svg {
    color: var(--color-warning);
  }

  .Toastify__close-button {
    display: initial;
    position: absolute;
    top: 8px;
    right: 12px;

    svg {
      color: var(--color-white);
    }
  }
}
