@use "../../scss/generic/Variables.module.scss";

.className {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  color: white;
  border-radius: 50rem;
  display: inline-flex;
  min-height: 1.5rem;
  min-width: 1.5rem;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  padding: 0.35em 0.65em;
  background: var(--color-medium);

  // Align number perfectly center
  span {
    margin-left: -0.06rem;
  }
}

// Sizes

.small {
  min-height: 1.25rem;
  min-width: 1.25rem;
}

.large {
  min-height: 2rem;
  min-width: 2rem;
}

// Colors

.primary {
  background: var(--color-primary);
}

.danger {
  background: var(--color-error);
}
