.header {
  position: fixed;
  z-index: var(--z-index-drawer);
  top: var(--bar-height);
  right: 0;
  left: 0;
  background-color: var(--color-white);
  width: calc(100% - 5px); // To account for the custom scrollbar
  height: var(--bar-height);
  padding-top: 0.75rem;
  padding-bottom: 0.8125rem;
}

.inner {
  position: relative;
  top: 0;
  padding-top: 8rem;
  height: 100%;
  overflow-y: auto;
  padding-bottom: var(--spacing-8);
  font-size: var(--font-size-sm);

  input[type="number"] {
    appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none;
    }
  }
}

.label {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  padding-bottom: 0.825rem;
  line-height: 0.9;
  display: block;
}

.closeBtn {
  position: absolute;
  top: 1rem;
  left: -1.2rem;
  background: var(--color-white);
  border: 1px solid var(--border-color);
}

.advancedFiltersWrapper {
  background: var(--color-gray-200);
  border-radius: var(--radius-small);
}

.advancedFiltersAccordion {
  border-bottom: none;
}

.advancedFiltersHeader {
  padding-top: var(--spacing-4);
  padding-bottom: var(--spacing-4);
}

.advancedFiltersContents {
  padding-bottom: var(--spacing-5);
}
