@use "../../scss/utils" as *;
@use "../../scss/utils/Breakpoints.module.scss" as breakpoints;

.className {
  display: flex;
  flex-wrap: wrap;
}

.noWrap {
  flex-wrap: nowrap;
}

/* Justify classes */
.justifyStart {
  justify-content: flex-start;
}

.justifyCenter {
  justify-content: center;
}

.justifyEnd {
  justify-content: flex-end;
}

.justifySpaceBetween {
  justify-content: space-between;
}

.justifySpaceAround {
  justify-content: space-around;
}

.justifySpaceEvenly {
  justify-content: space-evenly;
}

/* Align classes */
.alignStart {
  align-items: flex-start;
}

.alignCenter {
  align-items: center;
}

.alignEnd {
  align-items: flex-end;
}

.alignStretch {
  align-items: stretch;
}

.alignBaseline {
  align-items: baseline;
}
