@use "sass:color";
@use "../../../../../scss/utils/Breakpoints.module.scss" as breakpoints;

$color-word: #113f92;
$color-excel: #107c41;
$color-powerpoint: #c43e1c;

.modalPortal {
  position: relative;
  z-index: var(--z-index-tooltip);
}

.childrenWrapper {
  width: 100%;
}

.addCollaboratorBtn {
  @include breakpoints.breakpoint-down("sm") {
    margin-bottom: 1rem;
    width: 100%;
  }
}

.openDocumentBtn {
  @include breakpoints.breakpoint-down("sm") {
    width: 100%;
  }

  &.word {
    color: $color-word;

    &:hover {
      color: color.adjust($color-word, $lightness: -10%);
    }
  }

  &.excel {
    color: $color-excel;

    &:hover {
      color: color.adjust($color-excel, $lightness: -10%);
    }
  }

  &.powerpoint {
    color: $color-powerpoint;

    &:hover {
      color: color.adjust($color-powerpoint, $lightness: -10%);
    }
  }
}

.versionNotesForwardBtn {
  min-width: 6.125rem;
}
