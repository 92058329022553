@use "../../../../../scss/utils/Breakpoints.module.scss" as breakpoints;

.controlsWrapper {
  display: grid;
  gap: 3px;
  grid-template-areas:
    "datepicker"
    "autoExpire"
    "autoRemind";

  @include breakpoints.breakpoint-up(md) {
    grid-template-areas:
      "datepicker datepicker"
      "autoExpire autoRemind";
  }

  @include breakpoints.breakpoint-up(xl) {
    grid-template-areas: "datepicker autoExpire autoRemind";
  }
}

.datepicker {
  grid-area: datepicker;
}

.autoExpire {
  grid-area: autoExpire;
}

.autoRemind {
  grid-area: autoRemind;
}

.control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--radius-small);
  padding: var(--spacing-4) var(--spacing-5);
  min-height: 60px;
  background: var(--color-white);
}

.formField {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  max-width: 20rem;

  @include breakpoints.breakpoint-up(md) {
    flex: unset;
  }
}

.labelWrapper {
  @include breakpoints.breakpoint-up(md) {
    min-width: 12.5rem;
  }
}
