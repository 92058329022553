.element {
  --scrollbar-thickness: thin;
}

.x {
  overflow-x: auto;
  scrollbar-width: var(--scrollbar-thickness);

  &::-webkit-scrollbar {
    background-color: transparent;
    height: 5px;
    width: 8px;
    margin: 2px 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-gray-300);
  }
}

.y {
  overflow-y: auto;
  scrollbar-width: var(--scrollbar-thickness);

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
    height: 8px;
    margin: 0 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-gray-300);
  }
}
