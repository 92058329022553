@use "../../scss/utils/Breakpoints.module.scss" as breakpoints;

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include breakpoints.breakpoint-down(md) {
    overflow: visible;
    white-space: normal;
    word-break: break-word;
  }
}

.hidden {
  visibility: hidden;
  max-height: 0;
}
