:root {
  --phone-input-color-focus: var(--color-primary);
  --phone-input-international-icon-phone-opacity: 0.8;
  --phone-input-international-icon-globe-opacity: 0.65;
  --phone-input-country-select-margin-right: 0.35em;
  --phone-input-country-select-arrow-width: 0.3em;
  --phone-input-country-select-arrow-margin-left: var(--phone-input-country-select-margin-right);
  --phone-input-country-select-arrow-border-width: 1px;
  --phone-input-country-select-arrow-opacity: 0.45;
  --phone-input-country-select-arrow-color: inherit;
  --phone-input-country-select-arrow-color-focus: var(--phone-input-color-focus);
  --phone-input-country-select-arrow-transform: rotate(45deg);
  --phone-input-country-flag-aspect-ratio: 1.5;
  --phone-input-country-flag-height: 1.5em;
  --phone-input-country-flag-border-width: 1px;
  --phone-input-country-flag-border-color: rgba(0 0 0 50%);
  --phone-input-country-flag-border-color-focus: var(--phone-input-color-focus);
  --phone-input-country-flag-background-color-loading: rgba(0 0 0 10%);
}

.PhoneInput {
  /* This is done to stretch the contents of this component. */
  display: flex;
  align-items: center;
  border: 1px solid var(--input-border-color);
  border-radius: var(--radius-small);

  input {
    border: none;
    padding: var(--button-py) 0;
  }
}

.PhoneInput--disabled.PhoneInputInput {
  cursor: not-allowed;
}

.PhoneInputInput {
  /* The phone number input stretches to fill all empty space */
  flex: 1;

  /* The phone number input should shrink
         to make room for the extension input */
  min-width: 0;
  font-size: 1rem;
  padding: 0.625rem 0.3125rem;
  border: 1px solid var(--input-border-color);
  border-radius: var(--radius-small);

  &:focus,
  &:focus-visible {
    border-color: var(--color-info);
    outline: none;
  }
}

.PhoneInputCountryIcon {
  width: calc(var(--phone-input-country-flag-height) * var(--phone-input-country-flag-aspect-ratio));
  height: var(--phone-input-country-flag-height);
}

.PhoneInput--disabled .PhoneInputCountryIcon {
  opacity: 0.5;
}

.PhoneInputCountryIcon--square {
  width: var(--phone-input-country-flag-height);
}

.PhoneInputCountryIcon--border {
  /* Removed `background-color` because when an `<img/>` was still loading
         it would show a dark gray rectangle. */

  /* For some reason the `<img/>` is not stretched to 100% width and height
         and sometime there can be seen white pixels of the background at top and bottom. */
  background-color: var(--phone-input-country-flag-background-color-loading);

  /* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */

  /* For some reason the `<img/>` is not stretched to 100% width and height
         and sometime there can be seen white pixels of the background at top and bottom,
         so an additional "inset" border is added. */
  box-shadow: 0 0 0 var(--phone-input-country-flag-border-width) var(--phone-input-country-flag-border-color),
    inset 0 0 0 var(--phone-input-country-flag-border-width) var(--phone-input-country-flag-border-color);
}

.PhoneInputCountryIconImg {
  /* Fixes weird vertical space above the flag icon. */

  /* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
  display: block;

  /* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
         Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
  width: 100%;
  height: 100%;
}

.PhoneInputInternationalIconPhone {
  opacity: var(--phone-input-international-icon-phone-opacity);
}

.PhoneInputInternationalIconGlobe {
  opacity: var(--phone-input-international-icon-globe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: var(--phone-input-country-select-margin-right);
  border-right: 1px solid var(--input-border-color);
  padding: 0 0.3125rem;
}

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;

  &[disabled] {
    opacity: 0;
    cursor: not-allowed;
  }
}

.PhoneInputCountrySelectArrow {
  display: block;
  content: "";
  width: var(--phone-input-country-select-arrow-width);
  height: var(--phone-input-country-select-arrow-width);
  margin-left: var(--phone-input-country-select-arrow-margin-left);
  border-style: solid;
  border-color: var(--phone-input-country-select-arrow-color);
  border-width: 0 var(--phone-input-country-select-arrow-border-width)
    var(--phone-input-country-select-arrow-border-width) 0;
  transform: var(--phone-input-country-select-arrow-transform);
  opacity: var(--phone-input-country-select-arrow-opacity);
}

.PhoneInputCountrySelect:focus + {
  .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
    opacity: 1;
    color: var(--color-info);
  }

  .PhoneInputCountryIcon--border {
    box-shadow: 0 0 0 var(--phone-input-country-flag-border-width) var(--phone-input-country-flag-border-color-focus),
      inset 0 0 0 var(--phone-input-country-flag-border-width) var(--phone-input-country-flag-border-color-focus);
  }

  .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
    opacity: 1;
    color: var(--color-info);
  }
}
