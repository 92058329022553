@use "../../scss/utils/Breakpoints.module.scss" as breakpoints;

.group {
  @include breakpoints.breakpoint-down(md) {
    line-height: 1.25;
    margin-bottom: 0.25rem;
  }
}

.noWrap {
  @include breakpoints.breakpoint-up(xl) {
    white-space: nowrap;
  }
}

.center {
  text-align: left;
  justify-content: left;
  @include breakpoints.breakpoint-up(md) {
    text-align: center;
    justify-content: center;
  }
}

.eventsTableCardContainer {
  border-bottom: 1px solid var(--color-gray-400);
}

.interviewLogBtn {
  padding-top: 0;
  padding-bottom: 0;

  svg {
    max-width: 1rem;
    min-width: 1rem;
  }
}

.files {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  cursor: pointer;

  @include breakpoints.breakpoint-up(lg) {
    flex-wrap: nowrap;
  }

  svg:not(:last-child),
  div:not(:last-child) {
    margin-right: var(--spacing-3);
  }
}

.tableWrapper {
  padding-bottom: var(--spacing-6);
}

.interveiwLogBtn {
  white-space: nowrap;
}

.description {
  word-break: break-word;
}
