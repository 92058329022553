@use "../../../scss/generic/Variables.module.scss";
@use "../../../scss/mixins/breakpoints.module.scss";
@use "../../../scss/utils/Grid.module.scss";

// Columns with the correct sizes are automatically generated from "utils/Grid.module.scss".
// Sizes are calculated from the number of "$grid-columns" in "utils/SassVariables.module.scss".
// We currently use a 12 column grid.

// Order

.order-sm-first {
  order: -1 !important;
}

.order-sm-0 {
  order: 0 !important;
}

.order-sm-1 {
  order: 1 !important;
}

.order-sm-2 {
  order: 2 !important;
}

.order-sm-3 {
  order: 3 !important;
}

.order-sm-4 {
  order: 4 !important;
}

.order-sm-5 {
  order: 5 !important;
}

.order-sm-last {
  order: 6 !important;
}

.order-md-first {
  order: -1 !important;
}

.order-md-0 {
  order: 0 !important;
}

.order-md-1 {
  order: 1 !important;
}

.order-md-2 {
  order: 2 !important;
}

.order-md-3 {
  order: 3 !important;
}

.order-md-4 {
  order: 4 !important;
}

.order-md-5 {
  order: 5 !important;
}

.order-lg-first {
  order: -1 !important;
}

.order-lg-0 {
  order: 0 !important;
}

.order-lg-1 {
  order: 1 !important;
}

.order-lg-2 {
  order: 2 !important;
}

.order-lg-3 {
  order: 3 !important;
}

.order-lg-4 {
  order: 4 !important;
}

.order-lg-5 {
  order: 5 !important;
}

.order-lg-last {
  order: 6 !important;
}

.order-xl-first {
  order: -1 !important;
}

.order-xl-0 {
  order: 0 !important;
}

.order-xl-1 {
  order: 1 !important;
}

.order-xl-2 {
  order: 2 !important;
}

.order-xl-3 {
  order: 3 !important;
}

.order-xl-4 {
  order: 4 !important;
}

.order-xl-5 {
  order: 5 !important;
}

.order-xl-last {
  order: 6 !important;
}

.order-xxl-first {
  order: -1 !important;
}

.order-xxl-0 {
  order: 0 !important;
}

.order-xxl-1 {
  order: 1 !important;
}

.order-xxl-2 {
  order: 2 !important;
}

.order-xxl-3 {
  order: 3 !important;
}

.order-xxl-4 {
  order: 4 !important;
}

.order-xxl-5 {
  order: 5 !important;
}

.order-xxl-last {
  order: 6 !important;
}

/* Grow and alignment */

.noGrow {
  flex: unset;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

/* Flex align */

.alignSelfStretch {
  align-self: stretch;
}
